/**
 * @author tangganxiao
 * @time 2025-04-01
 * @description 元素观察器
 * 使用方法：
 * 1. 直接传入一个函数，当元素进入视图时，会调用该函数
 * 2. 传入一个数组，第一个元素为函数，第二个元素为配置项
 * 3. 配置项：
 *    - immediate: 是否立即执行
 *    - root: 观察的根元素
 *    - rootMargin: 根元素的边距
 *    - threshold: 阈值
 */

import { useIntersectionObserver } from '@vueuse/core';
import type { MaybeRef, ObjectDirective } from 'vue';

interface UseIntersectionObserverOptions {
  immediate?: boolean;
  root?: Document | MaybeRef;
  rootMargin?: string;
  threshold?: number | number[];
}
type BindingValueFunction = IntersectionObserverCallback;
type BindingValueArray = [BindingValueFunction, UseIntersectionObserverOptions];

export const Viewer: ObjectDirective<HTMLElement, BindingValueFunction | BindingValueArray> = {
  mounted(el, binding) {
    if (typeof binding.value === 'function') {
      useIntersectionObserver(el, binding.value);
    } else {
      useIntersectionObserver(el, ...binding.value);
    }
  },
};
