<template>
  <a-button v-bind="bindProps">
    <Content />
    <template v-if="isRenderIcon" #icon>
      <Icon :icon="icon" />
    </template>
    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" class="df-btn__spin">
      <circle cx="3" cy="12" r="2.5" />
      <circle cx="12" cy="12" r="2.5" />
      <circle cx="21" cy="12" r="2.5" />
    </svg>
  </a-button>
</template>

<script setup lang="tsx">
import { ShapeFlags } from '@vue/shared';
import type { ButtonSize } from 'ant-design-vue/es/button';
import { isArray, isFunction, isNil, isObject, isString, omit } from 'lodash-es';
import { useNamespace } from '../_hooks/use-namespace';
import type { DfBtnIcon, DfBtnProps } from './types';

const props = withDefaults(defineProps<DfBtnProps>(), {
  type: 'default',
  size: 'middle',
  shape: 'square',
});

const ns = useNamespace('btn');

const isRenderIcon = computed(() => !isNil(props.icon));

const aBtnSize = computed<ButtonSize>(() => {
  return props.size !== 'mini' ? props.size : 'small';
});

const type = computed(() => {
  return props.type !== 'default' ? props.type : 'tertiary';
});
const shape = computed(() => {
  return props.shape === 'default' ? 'square' : props.shape;
});

const cls = computed(() => [
  ns.b(),
  ns.m(type.value),
  ns.m(props.size),
  ns.m(shape.value),
  ns.is('block', props.block),
  ns.is('icon', isRenderIcon.value),
  ns.is('loading', props.loading),
]);

const bindProps = computed(() => {
  return omit(
    {
      ...props,
      type: 'primary',
      size: aBtnSize.value,
      shape: 'default',
      class: cls.value,
    },
    ['icon', 'loading'],
  ) as any;
});

const slots = useSlots();
const Icon = (props: { icon?: DfBtnIcon }) => {
  if (isString(props.icon)) {
    return <icon-font type={props.icon} />;
  } else if (isFunction(props.icon) || isObject(props.icon)) {
    return h(props.icon);
  } else {
    return null;
  }
};
const Content = () => {
  if (isRenderIcon.value) {
    return null;
  } else {
    let vnodes: any = slots.default?.();
    if (isArray(vnodes) && vnodes.length > 0) {
      vnodes = vnodes.map((vnode) => {
        // 文本节点需要包裹 span
        if (vnode.shapeFlag === ShapeFlags.TEXT_CHILDREN) {
          return <span>{vnode}</span>;
        } else {
          return vnode;
        }
      });
    }
    return vnodes ?? <span>{props.label}</span>;
  }
};
</script>

<style scoped lang="less">
.df-btn {
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px var(--df-btn-border-style, solid);

  height: var(--df-btn-size, 40px);
  border-radius: var(--df-btn-rouned, 8px);
  padding-inline-start: var(--df-btn-padding, 16px);
  padding-inline-end: var(--df-btn-padding, 16px);
  font-size: var(--df-btn-font-size, 14px);
  font-weight: var(--df-btn-font-weight, 600);
  line-height: var(--df-btn-leading, 20px);
  opacity: var(--df-btn-opacity, 1);

  &:disabled {
    --df-btn-opacity: 0.4;
  }
}

:deep(.anticon + span),
:deep(span + .anticon) {
  margin-inline-start: var(--df-btn-gap, 8px);
}
:deep(.anticon),
:deep(span + .anticon) {
  font-size: var(--df-btn-icon-size, 20px);
}

.df-btn,
.df-btn:hover,
.df-btn:active,
.df-btn:disabled {
  border-color: var(--df-btn-border-color);
  color: var(--df-btn-text-color);
  background-color: var(--df-btn-bg-color);
}

.df-btn--circle {
  width: var(--df-btn-size);
  height: var(--df-btn-size);
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 50%;
}

.df-btn--large {
  --df-btn-size: 48px;
  --df-btn-padding: 16px;
  --df-btn-gap: 10px;
  --df-btn-icon-size: 20px;
  --df-btn-font-size: 16px;
  --df-btn-font-weight: 600;
  --df-btn-leading: 22px;

  &.df-btn--square {
    --df-btn-rouned: 8px;
  }
  &.df-btn--round {
    --df-btn-rouned: 24px;
  }
}
.df-btn--middle {
  --df-btn-size: 40px;
  --df-btn-padding: 16px;
  --df-btn-gap: 8px;
  --df-btn-icon-size: 20px;
  --df-btn-font-size: 14px;
  --df-btn-font-weight: 600;
  --df-btn-leading: 20px;

  &.df-btn--square {
    --df-btn-rouned: 8px;
  }
  &.df-btn--round {
    --df-btn-rouned: 20px;
  }
}
.df-btn--small {
  --df-btn-size: 32px;
  --df-btn-padding: 12px;
  --df-btn-gap: 6px;
  --df-btn-icon-size: 16px;
  --df-btn-spin-size: 20px;
  --df-btn-font-size: 12px;
  --df-btn-font-weight: 600;
  --df-btn-leading: 16px;

  &.df-btn--square {
    --df-btn-rouned: 6px;
  }
  &.df-btn--round {
    --df-btn-rouned: 18px;
  }
}
.df-btn--mini {
  --df-btn-size: 24px;
  --df-btn-padding: 8px;
  --df-btn-gap: 4px;
  --df-btn-icon-size: 14px;
  --df-btn-spin-size: 16px;
  --df-btn-font-size: 12px;
  --df-btn-font-weight: 500;
  --df-btn-leading: 16px;

  &.df-btn--square {
    --df-btn-rouned: 4px;
  }
  &.df-btn--round {
    --df-btn-rouned: 16px;
  }
}

.df-btn--highlight {
  --df-btn-border-style: none;
  --df-btn-text-color: #ffffff;
  --df-btn-bg-color: #ff0035;

  &:hover {
    --df-btn-bg-color: #d9002d;
  }
  &:active {
    --df-btn-bg-color: #b20025;
  }
}
.df-btn--primary {
  --df-btn-border-style: none;
  --df-btn-text-color: #ffffff;
  --df-btn-bg-color: #070b10;

  &:hover {
    --df-btn-bg-color: #2d3541;
  }
  &:active {
    --df-btn-bg-color: #1c2129;
  }
}
.df-btn--tertiary {
  --df-btn-border-color: #d8dadf;
  --df-btn-text-color: #2d3541;
  --df-btn-bg-color: transparent;

  &:hover {
    --df-btn-border-color: #d8dadf;
    --df-btn-text-color: #2d3541;
    --df-btn-bg-color: #494f870d;
  }
  &:active {
    --df-btn-border-color: #070b10;
    --df-btn-text-color: #070b10;
    --df-btn-bg-color: #f0f0f5;
  }
}
.df-btn--dark {
  --df-btn-border-color: #070b10;
  --df-btn-text-color: #2d3541;
  --df-btn-bg-color: #f6f6fa;

  &:hover {
    --df-btn-border-color: #070b10;
    --df-btn-text-color: #2d3541;
    --df-btn-bg-color: #494f870d;
  }
  &:active {
    --df-btn-border-color: #070b10;
    --df-btn-text-color: #070b10;
    --df-btn-bg-color: #f0f0f5;
  }
}
.df-btn--special {
  --df-btn-text-color: #ff7c23;
  --df-btn-border-color: var(--df-btn-text-color);
  --df-btn-bg-color: #ffffff;

  &:hover {
    --df-btn-text-color: #ff9432;
  }
  &:active {
    --df-btn-text-color: #fd7326;
  }
  &:disabled {
    --df-btn-text-color: #ff7c23;
    --df-btn-bg-color: #ff7c231a;
  }
}

.df-btn--filled {
  --df-btn-border-style: none;
  --df-btn-text-color: #1a1a1a;
  --df-btn-bg-color: #f5f7fb;

  &:hover {
    --df-btn-text-color: #353538;
    --df-btn-bg-color: #f0f1f5;
  }
  &:active {
    --df-btn-text-color: #000;
    --df-btn-bg-color: #e9eaf0;
  }
  &:disabled {
    --df-btn-text-color: #cdcdcd;
    --df-btn-bg-color: #f0f1f5;
  }
}

.df-btn--text {
  --df-btn-border-style: none;
  --df-btn-text-color: #2d3541;
  --df-btn-bg-color: transparent;

  &:hover {
    --df-btn-text-color: #272e38;
    --df-btn-bg-color: #494f870d;
  }
  &:active {
    --df-btn-text-color: #080e11;
    --df-btn-bg-color: #535b881a;
  }
  &:disabled {
    --df-btn-text-color: #2d3541;
    --df-btn-bg-color: transparent;
  }

  &.df-btn--large {
    --df-btn-size: 40px;
    --df-btn-padding: 10px;
    --df-btn-gap: 8px;
    --df-btn-icon-size: 16px;
    --df-btn-font-size: 14px;
    --df-btn-leading: 20px;
  }
  &.df-btn--middle {
    --df-btn-size: 32px;
    --df-btn-padding: 8px;
    --df-btn-gap: 6px;
    --df-btn-icon-size: 16px;
    --df-btn-font-size: 12px;
    --df-btn-leading: 16px;
  }
  &.df-btn--small {
    --df-btn-size: 24px;
    --df-btn-padding: 6px;
    --df-btn-gap: 4px;
    --df-btn-icon-size: 14px;
    --df-btn-font-size: 12px;
    --df-btn-font-weight: 500;
    --df-btn-leading: 16px;
  }
}
.df-btn--link {
  display: inline-flex;
  padding-inline-start: 0;
  padding-inline-end: 0;
  height: 24px;
  border: none;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #2661f1;

  &:hover {
    color: #1f57e0;
    text-decoration: underline;
    text-decoration-color: #1f57e0;
  }
  &:active {
    color: #144ace;
    text-decoration: underline;
    text-decoration-color: #144ace;
  }
  &:disabled,
  &[disabled] {
    color: #144ace;
    text-decoration: none;
    opacity: 0.4;
  }
}

.is-icon {
  width: var(--df-btn-size);
  height: var(--df-btn-size);

  --df-btn-text-color: #2f3540;
  --df-btn-padding: 0;
  --df-btn-border-style: none;

  :deep(.anticon) {
    --df-btn-icon-size: var(--df-btn-font-size);
  }

  &.df-btn--large {
    --df-btn-size: 40px;
    --df-btn-font-size: 24px;
    --df-btn-rouned: 8px;
  }
  &.df-btn--middle {
    --df-btn-size: 32px;
    --df-btn-font-size: 20px;
    --df-btn-rouned: 6px;
  }
  &.df-btn--small {
    --df-btn-size: 24px;
    --df-btn-font-size: 18px;
    --df-btn-rouned: 4px;
  }
  &.df-btn--mini {
    --df-btn-size: 20px;
    --df-btn-font-size: 16px;
    --df-btn-rouned: 2px;
  }

  &:hover {
    --df-btn-text-color: #2f3540;
    --df-btn-bg-color: #494f870d;
  }
  &:active {
    --df-btn-text-color: #080b10;
    --df-btn-bg-color: #535b881a;
  }
}

.is-block {
  display: flex;
  width: 100%;
}

.is-loading {
  position: relative;
  pointer-events: none;
  & > *:not(.df-btn__spin) {
    opacity: 0 !important;
  }
}
.df-btn__spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--df-btn-spin-size, 24px);

  circle {
    animation: spin 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }
    &:nth-child(2) {
      animation-delay: -0.16s;
    }

    @keyframes spin {
      0%,
      80%,
      100% {
        opacity: 0.5;
      }
      40% {
        opacity: 1;
      }
    }
  }
}
</style>
