<template>
  <div
    :data-sensors-exposure-event-name="GA4Event.CONTESTS_EXPOSURE"
    :data-sensors-exposure-property-content_name="contest.title"
    :data-sensors-exposure-property-content_sequence="seq"
    class="contests-banner relative grid cursor-pointer content-center overflow-hidden rounded-lg"
  >
    <NuxtLink
      :to="url"
      :target="target"
      class="left relative"
      :style="{ backgroundImage: `url(${BG})`, backgroundSize: 'cover' }"
      @click="onTraceContestClick('contest card')"
    >
      <div
        :class="[
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
          'h-full max-h-[calc(100%-80px)] min-h-fit w-full max-w-[calc(100%-64px)]',
          'flex flex-col justify-between',
        ]"
      >
        <div class="flex flex-col gap-2">
          <div class="inline-flex items-center gap-2">
            <div v-if="!ended" class="inline-flex flex-shrink-0 items-center gap-1 rounded-[34px] bg-[#FF00350F] py-1 pl-[6px] pr-3 text-[#FF0035]">
              <ClientOnly>
                <icon-font type="icon-time-line" class="text-[16px]" />
              </ClientOnly>
              <span class="text-[12px] font-[500] leading-[20px]">Ends in {{ endDay }} days</span>
            </div>
            <div v-else class="inline-flex flex-shrink-0 items-center gap-1 rounded-[34px] bg-[#5161751A] py-1 pl-[6px] pr-3 text-[#070B10]">
              <ClientOnly>
                <icon-font type="icon-time-line" class="text-[16px]" />
              </ClientOnly>
              <span class="text-[12px] font-[500] leading-[20px]">ENDED</span>
            </div>
            <span class="overflow-hidden truncate text-[12px] leading-[20px] text-[#8C9098]" :title="`${rangeTime[0]}-${rangeTime[1]}`">
              {{ rangeTime[0] }}-{{ rangeTime[1] }}
            </span>
          </div>
          <h3 class="title mb-0 break-words text-[20px] font-semibold leading-[28px] text-[#070B10]">{{ title }}</h3>
          <div v-if="awards.length > 0" class="h-10">
            <ClientOnly>
              <Award :items="awards" />
            </ClientOnly>
          </div>
        </div>
        <div
          class="mt-4 inline-flex w-fit items-center rounded-lg bg-[#FF0035] px-4 py-2 text-[#FFF] shadow-[0px_11px_14.8px_0px_#ff003533]"
          @click="onTraceContestClick('enter now')"
        >
          <svg width="24" height="24" fill="currentColor" focusable="false">
            <use xlink:href="#icon-contests-enter" />
          </svg>
          <span class="ml-2.5 text-level-4 font-semibold">Enter Now</span>
        </div>
      </div>
    </NuxtLink>
    <div class="right grid content-center gap-[1px]" @click="onTemplateClick">
      <template v-for="(item, index) in makings" :key="item.id">
        <RatioBox padding-top="75%" :class="['item group/cover', index === 0 ? 'col-span-2 row-span-2' : '']">
          <img v-lazy="getOssProcessedImage(item.cover, 'm_fill,w_878,h_592', 'webp')" class="h-full w-full object-cover" />
          <ClientOnly>
            <Mask :item="item" />
          </ClientOnly>
        </RatioBox>
      </template>
    </div>
  </div>
</template>

<script setup lang="tsx">
import BG from 'assets/img/home/contests-bg.png';
import Award from '~/components/featured/contests/Award.vue';
import dayjs from 'dayjs';
import { Lazy } from '~/utils/directive';
import { withDirectives } from 'vue';
import { NuxtLink } from '#components';
import { GA4Event } from '~/types/ga4';

interface Props {
  contest: any;
  seq: number;
}

const props = withDefaults(defineProps<Props>(), {
  contest: {},
  seq: 0,
});

const target = isInWujie() ? '_blank' : '_self';

const Mask = (props: { item: any }) => {
  const { item } = props;
  const id = item.createdBy;
  const name = item.createdByName;
  const avatar = getOssProcessedImage(item.createdByHeadpic, 'm_fill,w-80,h-80', 'webp');
  const onClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    navigateTo(`/profile/${id}`, { open: { target } });
  };

  return (
    <>
      <div class="absolute inset-0 bg-[#00000033] opacity-0 transition-all group-hover/cover:opacity-30"></div>
      <div class="absolute bottom-0 left-0 right-0 h-[30%] bg-[linear-gradient(0deg,#000000_0%,rgba(0,0,0,0)_100%)] opacity-0 transition-all group-hover/cover:opacity-60"></div>
      <div
        class="absolute bottom-2 left-2 right-2 inline-flex h-5 w-[calc(100%-16px)] items-center gap-[11px] opacity-0 transition-all group-hover/cover:opacity-100"
        onClick={onClick}
      >
        {withDirectives(<img class="h-5 w-5 rounded-full object-cover" />, [[Lazy, avatar]])}
        <div class="w-calc(100%-31px) overflow-hidden truncate text-level-8 text-[#FFF]">{name}</div>
      </div>
    </>
  );
};

const contest = computed(() => props.contest);
const title = computed(() => contest.value.title);
const rangeTime = computed(() => [dayjs.unix(contest.value.startTime).format('YYYY MM/DD'), dayjs.unix(contest.value.endTime).format('YYYY MM/DD')]);
const endDay = computed(() => dayjs.unix(contest.value.endTime).diff(dayjs(), 'day'));
const ended = computed(() => dayjs().unix() >= contest.value.endTime);
const makings = computed(() => contest.value.makingCards as any[]);
const awards = computed(() => {
  const items: any[] = contest.value?.configItem?.info?.prizeCard?.prizeGroups?.[0]?.awards ?? [];
  return items.slice(0, 3).map((item) => item.prizeName);
});

const isTemplateActivity = computed(() => contest.value.configItem?.actId);
const url = computed(() => {
  if (isTemplateActivity.value) {
    return contest.value.extra.pageUrl;
  } else {
    return `/activity/temp-page/${contest.value.id}/${contest.value?.configItem?.actConfId}`;
  }
});

const templateURL = computed(() => {
  if (isTemplateActivity.value) {
    return url.value;
  } else {
    return `${url.value}?activeTabIndex=2`;
  }
});

const onTemplateClick = () => {
  onTraceContestClick('template');
  navigateTo(templateURL.value, { open: { target } });
};

const { traceContestsClick } = useSensors();
const onTraceContestClick = (click_element: string) => {
  traceContestsClick({
    contest_name: props.contest.title,
    sequence: props.seq,
    click_element,
  });
};
</script>

<style scoped lang="less">
.contests-banner {
  grid-template-columns: repeat(7, minmax(0, 1fr));
  .left {
    grid-column: span 2 / span 2;
  }
  .right {
    grid-column: span 5 / span 5;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media (max-width: 1280px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));

    .left {
      grid-column: span 2 / span 2;
    }
    .right {
      grid-column: span 4 / span 4;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      .item:nth-of-type(n + 6) {
        display: none;
      }
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));

    .left {
      grid-column: span 2 / span 2;
    }
    .right {
      grid-column: span 3 / span 3;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      .item:nth-of-type(n + 4) {
        display: none;
      }
    }
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .left {
      grid-column: span 2 / span 2;
    }
    .right {
      grid-column: span 2 / span 2;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .item:nth-of-type(n + 2) {
        display: none;
      }
    }
  }
}

.title {
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webki-box-orient: vertical;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.cover {
  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover::before {
    opacity: 1;
  }
}
</style>
