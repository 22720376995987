import { OpenFrom } from '@/types';
import qs from 'qs';

// 是否存在 MeApi (目前在pc端xcs软件内才有值)
export function isMeApi() {
  try {
    return window?.parent?.MeApi;
  } catch (e) {
    console.warn(e);
    return false;
  }
}

/**
 * 在手机/iPad的xcs app为 真 ，在浏览器中为 假 (包括安卓和iOS)
 * 例如：
 * 做链接打开判断，若在 移动端app中，当前页打开链接
 * if (isAppWebkit()) {...}
 */
export function isAppWebkit() {
  return (window as any)?.LinkNative || (window as any)?.webkit?.messageHandlers?.LinkNative;
}

/**
 * 在ipad浏览器中为 真，在手机/电脑/ipad的xcs软件中都为 假
 * 例如：
 * 做链接打开判断，若在 iPad浏览器中，新开窗口到浏览器打开
 * if (isIpad()) {...}
 */
export function isIpad() {
  return !isAppWebkit() && (navigator?.userAgent?.match(/(iPad)/) || (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1));
}

/**
 * Electron 环境
 */
export function isElectron() {
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent?.indexOf('Electron') >= 0) {
    return true;
  }
  return false;
}

/**
 * 是否在pc xcs软件内部环境(xcs的pc端软件是使用 electron 构建的，其实只需要用isElectron()就可以判断是否在pc xcs软件内部环境)
 * 例如：
 * 做链接打开判断，若在 PC端app中，新开窗口到浏览器打开
 * if (isElectronXcs()) {...}
 */
export function isElectronXcs() {
  return isElectron() && isMeApi();
}

/**
 * 是否为移动端系统
 */
export function isMobileSystem() {
  return typeof window !== 'undefined' && /Mobi|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/**
 * 是否为iPad系统
 */
export function isIpadSystem() {
  return (
    typeof window !== 'undefined' &&
    Boolean(navigator.userAgent.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
  );
}

/**
 * 区分移动端和PC端
 */
export function isMobileOrPad() {
  return isMobileSystem() || isIpadSystem();
}

/**
 * 根据设备信息判断是否为pc端
 */
export const IsPC = () => {
  const userAgentInfo = navigator.userAgent as any;
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
    'Mobile',
    'iphone',
    'android',
    'windows phone',
    'ipod',
    'blackberry',
    'webos',
    'opera mini',
    'mobile',
    'tablet',
  ];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  // 进一步判断触控设备
  if (flag && navigator.maxTouchPoints > 0) {
    // 这里可以添加更多的判断条件来区分触控PC和移动设备
    if (userAgentInfo.indexOf('Windows NT') > 0 || userAgentInfo.indexOf('Macintosh') > 0) {
      flag = true; // 触控PC
    } else {
      flag = false; // 移动设备
    }
  }
  return flag;
};

/**
 * 判断当前是否在应用内
 * @returns 返回布尔值，表示是否在应用内
 */
export function isInApp() {
  return isInClient() && (isMeApi() || isAppWebkit() || isElectron());
}
/**
 * 判断当前是否在iPad设备上
 * @returns 返回布尔值，表示是否在iPad设备上
 */
export function isInIPad() {
  return isInClient() && /iPad/.test(navigator?.userAgent);
}
/**
 * 判断当前是否在移动端设备上
 *
 * @returns 返回布尔值，表示是否在移动端设备上
 */
export function isInMobile() {
  return isInClient() && /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
/**
 * 判断当前是否在PC端
 *
 * @returns 返回布尔值，表示是否在PC端
 */
export function isInPC() {
  return isInClient() && !isInIPad() && !isInMobile();
}

/**
 * 判断当前是否在客户端环境中运行
 *
 * @returns 返回布尔值，表示是否在客户端环境中运行
 */
export function isInClient() {
  return typeof window !== 'undefined';
}
/**
 * 判断当前是否在服务器端运行
 *
 * @returns 返回布尔值，表示是否在服务器端运行
 */
export function isInServer() {
  return !isInClient();
}

/**
 * 判断当前是否在PC端应用内
 *
 * @returns 返回布尔值，表示是否在PC端应用内
 */
export function isInPCApp() {
  if (isInClient()) {
    return isInApp() && isInPC();
  } else {
    const nuxtApp = getNuxtApp();
    const headers = nuxtApp?.ssrContext?.event.headers;
    const userAgent = headers?.get('user-agent') ?? '';
    return /Electron/.test(userAgent);
  }
}
/**
 * 判断当前是否在iPad应用内
 *
 * @returns 返回布尔值，表示是否在iPad应用内
 */
export function isInIPadApp() {
  return isInApp() && isInIPad();
}
/**
 * 判断当前是否在移动端应用内
 *
 * @returns 返回布尔值，表示是否在移动端应用内
 */
export function isInMobileApp() {
  return isInApp() && isInMobile();
}

export function isInApple() {
  return isInClient() && /iPad|iPhone|iPod|Mac/i.test(navigator.userAgent);
}

export function isInIPhone() {
  return isInClient() && /iPhone/i.test(navigator.userAgent);
}

export const isInIframe = () => {
  return window.self !== window.parent;
};

/**
 * 是否在wujie微前端框架中
 */
export function isInWujie() {
  if (isInClient()) {
    return typeof (window as any).$wujie !== 'undefined';
  } else {
    const nuxtApp = getNuxtApp();
    const headers = nuxtApp?.ssrContext?.event.headers;
    const origin = headers?.get('origin') ?? '';
    // console.log('[host-env]', 'referer:', referer, 'studio-site:', import.meta.env.VITE_ATOMM_STUDIO_SITE);
    return origin.includes(import.meta.env.VITE_ATOMM_STUDIO_SITE);
  }
}

/**
 * 判断是否从studio的editor进入
 */
export function isOpenFromStudioEditor() {
  if (isInClient()) {
    const query = qs.parse(globalThis?.location?.search, { ignoreQueryPrefix: true });
    return query?.openFrom === OpenFrom.StudioEditor;
  } else {
    const nuxtApp = getNuxtApp();
    const url = nuxtApp?.ssrContext?.event?.node?.req?.url;
    return url?.includes(`openFrom=${OpenFrom.StudioEditor}`);
  }
}
