<template>
  <a-popover
    v-model:open="open"
    placement="bottom"
    trigger="hover"
    :z-index="100"
    :arrow="false"
    :overlay-inner-style="{ padding: '4px' }"
    :auto-adjust-overflow="true"
    :align="align"
  >
    <icon-font
      type="icon-more-fill"
      class="flex h-6 w-6 rotate-90 items-center justify-center rounded-full bg-[#fff] text-text-primary opacity-90"
      @click.prevent.stop
    />
    <template #content>
      <div>
        <div
          v-for="item in actionList"
          :key="item.type"
          class="flex cursor-pointer flex-nowrap rounded-lg px-3 py-2.5 text-text-primary hover:bg-background-2"
          @click="onClickAction(item.type)"
        >
          <icon-font v-if="item.icon" :type="item.icon" class="mr-2 text-[20px]" :style="{ color: item.color }" />
          <span class="whitespace-nowrap text-level-4">{{ item.text }}</span>
        </div>
      </div>
    </template>
  </a-popover>
</template>

<script setup lang="ts">
type ActionItem = {
  type: string;
  icon: string;
  text: string;
  color?: string;
};

type PropsType = {
  actionList: ActionItem[];
};
type EmitType = {
  (e: 'command', type: string): void;
};
defineProps<PropsType>();
const emits = defineEmits<EmitType>();

const align = {
  overflow: {
    adjustX: true,
    adjustY: true,
    alwaysByViewport: true,
  },
};
const open = ref(false);

const onClickAction = (type: string) => {
  emits('command', type);
  const timer = setTimeout(() => {
    open.value = false;
    clearTimeout(timer);
  }, 300);
};

defineExpose({
  open,
});
</script>

<style lang="less" scoped></style>
