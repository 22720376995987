export default function convertTitleToSlug(title: string) {
  if (!title) {
    return title;
  }
  return title
    .toLowerCase() // 转换为小写
    .trim() // 去除首尾空格
    .replace(/[^a-z0-9\s-]/g, '') // 移除非字母数字字符
    .replace(/\s+/g, '-') // 将空格替换为中横线
    .replace(/-+/g, '-'); // 合并多个中横线为一个
}
