<template>
  <div class="author-user-img group/author flex w-full cursor-pointer items-center gap-1 mobile:gap-1" :title="userName" @click.stop="handleClick">
    <div class="author-img flex flex-shrink-0 mobile:h-5 mobile:w-5" :style="{ '--s': imgSize }">
      <img
        v-lazy="getOssImage || ''"
        class="use-img-default relative flex rounded-full object-cover mobile:!h-5 mobile:!w-5"
        :alt="`atomm-creator-avatar`"
      />
    </div>
    <span class="user-name one-line-ellipsis font-sans text-level-7 !text-text-tertiary group-hover/author:!text-text-primary" :title="userName">
      {{ userName }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useUserInfo } from '@/stores/user';

type PropsType = {
  userId: number;
  userImg?: string | undefined;
  userName?: string;
  imgSize?: string;
  clickable?: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  userName: '',
  userImg: '',
  imgSize: '24px',
  clickable: true,
});

const useUserInfoStore = useUserInfo();

const getOssImage = computed(() => {
  const { userImg, imgSize } = props;
  if (!userImg) {
    return '';
  }
  return getOssProcessedImage(
    userImg?.split('?')[0],
    `m_fill,w_${Number(imgSize.replace('px', '')) * 2},h_${Number(imgSize.replace('px', '')) * 2}`,
    'webp',
  );
});

const handleClick = (e: Event) => {
  e.stopPropagation();
  e.preventDefault();

  if (!props.clickable) {
    return;
  }

  if (props.userId === useUserInfoStore.myInfo.id) {
    if (isOpenFromStudioEditor()) {
      createAlink(`/user-center?active=projects`, '_blank');
    } else {
      navigateTo({ path: '/user-center', query: { active: 'projects' } });
    }
  } else {
    if (isOpenFromStudioEditor()) {
      createAlink(`/profile/${props.userId}`, '_blank');
    } else {
      navigateTo(`/profile/${props.userId}`);
    }
  }
};
</script>

<style lang="less" scoped>
.author-user-img {
  .author-img {
    img {
      width: var(--s, 20px);
      height: var(--s, 20px);
    }
  }
}
</style>
