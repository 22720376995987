<template>
  <section v-if="isShow" class="mb-6 px-10 mobile:hidden mobile:px-4">
    <div class="mx-auto flex w-full flex-col gap-4" style="max-width: var(--max-w)">
      <div v-for="column in columns" v-show="inited" :key="column.moduleTitle" class="w-full">
        <h2 class="mb-4 py-1 text-level-1 font-semibold text-[#070B10]">{{ column.moduleTitle }}</h2>
        <Lane v-show="inited" :items="column.collectionList" :gap="24" :column="4">
          <template #item="{ item }">
            <block-collection-item :making-item="item" :multiple="false" :draggable="false" :img-group="false" source="homepage" />
            <div class="mx-auto h-2 w-[90%] rounded-b bg-[#E7E9ED]"></div>
            <div class="mx-auto h-2 w-[85%] rounded-b bg-[#F1F2F5]"></div>
          </template>
        </Lane>
      </div>
      <div v-if="!inited">
        <DfSkeleton class="mb-4 h-10" />
        <LaneSkeleton />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { getCollectionsOperationListApi } from '~/api';
import Lane from '~/components/common/lane/index.vue';
import LaneSkeleton from '../common/LaneSkeleton.vue';

const columns = useState(() => [] as any[]);

const mounted = ref(true);
const loaded = useState(() => false);
const inited = computed(() => loaded.value && mounted.value);
const isShow = computed(() => {
  return !(loaded.value && columns.value.length === 0);
});

const load = async () => {
  try {
    const res = await getCollectionsOperationListApi.fetch();
    columns.value = res.data?.collectionModule ?? [];
    // console.log('[home-columns]', columns.value);
  } finally {
    loaded.value = true;
  }
};

const refresh = async () => {
  const res = await getCollectionsOperationListApi.fetch();
  const newColumns = res.data?.collectionModule ?? [];

  newColumns.forEach((newColumn, i) => {
    newColumn.collectionList.forEach((newCollection: any, j: number) => {
      Object.assign(columns.value[i].collectionList[j], newCollection);
    });
  });
};

onMounted(async () => {
  try {
    if (!loaded.value || mounted.value) {
      return;
    }
    await load();
  } finally {
    mounted.value = true;
  }
});

onActivated(async () => {
  // console.log('[home-columns]', 'activated');
  if (!loaded.value || !mounted.value) {
    return;
  }
  await refresh();
});

if (!loaded.value) {
  if (import.meta.client) {
    load();
  } else {
    await load();
  }
}

if (import.meta.client) {
  console.log('[home-columns]', columns.value);
}
</script>

<style scoped lang="less">
:deep(.df-lane-wrap) {
  .df-lane {
    @media (max-width: 1024px) {
      --c: 3;
    }
  }
  .df-lane-item {
    padding-bottom: 16px;
  }
  .df-lane-arrow {
    top: calc(50% - 16px);
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.collections-column-spin {
  grid-template-columns: repeat(var(--c, 4), minmax(0, 1fr));
  @media (max-width: 1024px) {
    --c: 3;
    .item:nth-of-type(n + 4) {
      display: none;
    }
  }
}
</style>
