<template>
  <div
    ref="elRef"
    :class="['relative inline-flex h-10 max-w-full overflow-hidden rounded-lg bg-[#FFFFFF63]', mounted ? 'opacity-100' : 'opacity-0']"
    :style="{
      width: mounted ? `${w}px` : '100%',
      border: '1px solid #ff7c2326',
    }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div
      v-for="(item, index) in items"
      :key="colors[index]"
      class="item absolute left-0 top-0 inline-flex w-full flex-nowrap items-center gap-2 px-3 py-2"
      :style="{
        color: colors[index],
        transform: `translateY(${offsetArr[index]}px)`,
        transition: index === activeIdx || (!transitionEnding && index === lastIdx) ? 'transform 0.5s' : '',
      }"
      @transitionend="onTransitionend"
    >
      <svg width="20" height="20" fill="currentColor" focusable="false">
        <use xlink:href="#icon-contests-cup" />
      </svg>
      <div class="award-label max-w-[calc(100%-28px)] overflow-hidden truncate text-nowrap text-[14px] font-[500] leading-[24px]">{{ item }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  items: any[];
}
const props = withDefaults(defineProps<Props>(), {
  items: [] as any[],
});

const w = ref(222);
const mounted = ref(false);
const elRef = shallowRef<HTMLElement>();

onMounted(() => {
  try {
    if (!elRef.value) {
      return;
    }
    const root = elRef.value;
    const itemEls = root.querySelectorAll('.item');
    let maxWidth = 0;
    itemEls.forEach((el) => {
      const textEl = el.querySelector('.award-label') as HTMLElement;
      maxWidth = Math.max(maxWidth, textEl.scrollWidth + 58);
    });
    w.value = maxWidth;
  } finally {
    mounted.value = true;
  }
});

const colors = ['#F3A11E', '#8592A0', '#C5906D'];

const items = computed(() => props.items.slice(0, 3));
const offsetArr = ref([0, 40, 40]);

let activeIdx = 0;
let lastIdx = 0;
let transitionEnding = false;
const ticker = useTicker({
  auto: true,
  period: 3000,
  callback: () => {
    lastIdx = activeIdx;
    activeIdx = (activeIdx + 1) % items.value.length;
    offsetArr.value[lastIdx] = -40;
    offsetArr.value[activeIdx] = 0;
    transitionEnding = false;
  },
});

const onTransitionend = () => {
  transitionEnding = true;
  offsetArr.value[lastIdx] = 40;
};

const onMouseEnter = () => {
  ticker.pause();
};
const onMouseLeave = () => {
  ticker.reset();
};
</script>
