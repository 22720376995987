import type { MakingAttr } from '~/types/constant';

export type ItemType = {
  id: number;
  aiAssistance?: boolean;
  aiImages?: string[];
  aiExtra?: any;
  activity?: any;
  objectType: string;
  objectId: number;
  title: string;
  summary: string;
  description: string;
  createdAt: number;
  updatedAt: number;
  createdBy: number;
  deletedAt: number;
  publishedAt: number;
  createdByName: string;
  createdByHeadpic: string;
  createdByDeleted: boolean;
  cover: string;
  images: string[];
  videos: VideoType[];
  actionSum: ActionSum;
  deviceId: number;
  deviceName: string;
  applyDevices: string[];
  powerId: number;
  powerName: string;
  topics: string[];
  categoryId: number;
  categoryName: string;
  isCreator: boolean;
  bundleInfos: BundleItem[];
  content: string;
  machines: Machine[];
  compatibleMachine?: any[];
  materials: string[];
  materialInfo: string;
  softwareId: number;
  softwareName: string;
  software?: string[];
  otherSoftware: string;
  contentTags: string[];
  paraSettings: ParaSetting[];
  makingTime: number;
  attachFiles: AttachFile[];
  type: string;
  userAction: UserAction;
  commentList: any[];
  userComment: string;
  quantity: number;
  extra: ExtraType;
  status: string;
  object: ItemType;
  checked: boolean;
  steps: StepsItem[];
  source?: string;
  originals?: any[];
  supportRemix?: boolean;
  license?: any;
  licenseId: number;
  tags?: string[];
  bundles?: number[];
  forSale?: boolean;
  hasDiscount?: boolean;
  hasInstruction?: boolean;
  instructionType?: 'file' | 'step';
  tutorialFiles?: any[];
  accessories?: any[];
  accessoryInfos?: any[];
  scenarios?: string[];
  printTags?: string[];
  tagInfos?: tagInfoItem[];
  origPrice: number;
  credits: number;
  hasRight: boolean;
  sellTag?: SellTag;
  sale?: string;
  saleStatus?: string;
  mstatus?: MStatus | string;
  origPriceCent?: number;
  origPriceYuan?: number;
  discount?: Discount;
  difficultyLevel?: any;
  device?: any;
  latestAudit?: any;
  hasLiveVersion?: boolean;
  hintLabel?: any;
  difficultyLevelId: string | number;
  materialInfos?: MaterialInfosType[];
  materialTag?: string[]; // 耗材标签
  devicePowers?: devicePowersItem[];
  devicePowersInfos?: DevicePowersInfos[];
  productInfo?: ProductInfo;
  fileInfos?: FileInfo[];
  bom: any;
  recommend?: any;
  subscriptionAttr?: MakingAttr;
  rightSource?: RightSource;
  priceForSub: {
    proPrice: number;
    proPlusPrice: number;
  };
};

/**
 * Machine
 */
export interface Machine {
  id: number;
  name: string;
  powers: Record<string, any>;
}

/**
 * BundleItem
 */
export interface BundleItem {
  id: number;
  type: string;
  title: string;
  cover: string;
  blockCover: string;
  createdBy: number;
  origPrice: number;
  origPriceCent: number;
  hasRight: boolean;
}

/**
 * tags
 */
export interface tagInfoItem {
  tagJump: string;
  tagName: string;
  tagType: 'search' | 'topic' | 'combined';
}

/**
 * 材料列表 item
 */
export interface MaterialInfosType {
  id: number;
  name: string;
  image: string;
  icon: string;
  extra?: any;
}

/**
 * 产品信息 对象(未购买时展示的信息)
 */
export interface ProductInfo {
  iconTexts: IconText[];
  sellingPoints: string[];
  purchaseLink: string;
  overviews: string[];
  description: string;
}

export interface FileInfo {
  type: string; // 文件类型
  cnt: number; // 文件数量
}

export interface IconText {
  icon: string;
  text: string;
}

/**
 * steps 参数
 */
export interface StepsItem {
  title: string;
  images: string[];
  videos: any[];
  paraSettings: XthingsParaSetting[];
  groupParaSettings: any[][];
  content: string;
}
export interface XthingsParaSetting {
  uid: string;
  deviceName: string;
  powerName: string;
  laserType: string;
  processType: number;
  materials: string;
  power: number;
  speed: number;
  lines: string;
  pass: number;
  bitmapMode?: any;
  other: string;
  [key: string]: any;
}

/**
 * 设备、功率信息
 */
export interface DevicePowersInfos {
  name: string;
  extra: DevicePowersInfosExtra;
  powerNames: string[];
}

export interface devicePowersItem {
  deviceName: string;
  deviceId: number;
  powerName: string;
  powerId: number;
}
export interface DevicePowersInfosExtra {
  icon: string;
  adLink?: string;
}

/**
 * discount 优惠信息
 */
export interface Discount {
  id: number;
  createdAt?: number;
  createdBy: number;
  startTime?: number;
  endTime?: number;
  updatedAt: number;
  updatedBy: number;
  deletedAt?: any;
  discountId: number;
  makingId: number;
  discountPercent: number;
  disPriceCent: number;
  disPriceYuan: number;
  disPrice: number;
}
export enum MStatus {
  OffShelves = 'off_shelves', // 下架
  OnShelves = 'on_shelves', // 上架
  Todo = 'todo', // 待审核
  Approved = 'approved', // 审核通过
  Reject = 'reject', // 审核拒绝
}
/**
 * 售卖标签类型
 */
export enum SellTag {
  BestSeller = 'Best Seller',
  New = 'New',
  Exclusive = 'Exclusive',
  Bundle = 'Bundle', // 不会展示在前端
}
/**
 * 审核状态
 */
export enum BlockCardStatus {
  Todo = 'Under Review', // 待审核
  Approved = 'approved', // 通过
  Rejected = 'Rejected', // 拒绝
  Removed = 'Suspended', // 已下架
}

export type CommentType = {
  id: number;
  parentId: number;
  content: string;
  objectType: string;
  createdBy: number;
  createdAt: number;
  createdByHeadpic: string;
  createdByName: string;
  relatedId: number;
  relatedUserName: string;
  isCreator: boolean;
  userComment: string;
  commentVisible: boolean;
  actionSum: ActionSum;
  userAction: UserAction;
  subComments: CommentType[];
  images?: string[] | null;
};

export type AnswerType = {
  id: number;
  createdBy: number;
  createdAt: number;
  createdByName: string;
  createdByHeadpic: string;
  content: string;
  updatedAt: number;
  actionSum: ActionSum;
  userAction: UserAction;
  isCreator: boolean;
  isEditMode: boolean;
  editContent: string;
  objectType: string;
};

export type UserInfoType = {
  uid: number;
  headpic: string;
};

export type ExtraType = {
  hasVideo: boolean;
  hasAttachFile: boolean;
  blockCover: string;
  isInit?: number;
};

export type ParaSetting = {
  uid: string;
  processType: number;
  materials: string[];
  power: number;
  speed: number;
  pass: number;
  lines: number;
  bitmapMode: number;
  other: string;
  [key: string]: any;
};

export type ActionSum = {
  view: number;
  favorite: number;
  follow: number;
  comment: number;
  reply: number;
  like: number;
  unlike: number;
  answer: number;
  download: number;
  imageComment: number;
};

export type UserAction = {
  answer: number;
  comment: number;
  favorite: number;
  follow: number;
  like: 0;
  unlike: 0;
  view: 0;
  download: number;
};

export type AttachFile = {
  uid: string;
  name: string;
  url: string;
  extra?: any;
  isXcsFile?: boolean;
  fileCover?: string;
  xcsTags?: any;
  xcsFileNoCover?: boolean;
};

export type VideoType = {
  uid: string;
  videoUrl: string;
  thumbnailUrl: string;
  title: string;
  type?: string;
};

export type SecondConfirmArgsType = {
  apiFun: any; // 请求方法
  apiParams: any; // 请求参数
  type: string; // 弹窗类型
  okText?: string; // confirm按钮文案
  width?: number; // 弹窗宽度
  cb?: any; // 成功回调
};

export const enum BenefitTy {
  QUOTA = 'df_download',
  DISCOUNT = 'df_discount',
}
export const enum RightSource {
  ADMIN = 'admin',
  FREE = 'free',
  PAY = 'pay',
  SUBSCRIPTION = 'subscription',
}

export interface Paging {
  current: number;
  pageSize: number;
  total: number;
}
export interface ListWrap<T = any> {
  list: T[];
  pagination: Paging;
}

export type ListParams<T = NonNullable<unknown>> = Omit<Paging, 'total'> & T;
