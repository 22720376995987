export function isSameSiteURL(url = '') {
  return (
    !url.startsWith('http') ||
    url.startsWith(import.meta.env.VITE_ATOMM_SITE) ||
    url.startsWith(import.meta.env.VITE_DF_SITE) ||
    url.startsWith(import.meta.env.VITE_PROJECTS_SITE)
  );
}

export function getSameSiteURLPath(url = '') {
  if (url.startsWith(import.meta.env.VITE_ATOMM_SITE)) {
    return url.replace(import.meta.env.VITE_ATOMM_SITE, '');
  } else if (url.startsWith(import.meta.env.VITE_DF_SITE)) {
    return url.replace(import.meta.env.VITE_DF_SITE, '');
  } else if (url.startsWith(import.meta.env.VITE_PROJECTS_SITE)) {
    return url.replace(import.meta.env.VITE_PROJECTS_SITE, '');
  }
  return url;
}
