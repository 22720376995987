<template>
  <div ref="wrapRef" class="df-carousel-wrap">
    <div class="df-carousel" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
      <template v-if="ssr">
        <!--   SSR 渲染优化   -->
        <div v-if="!isClean" class="absolute inset-0" :style="{ visibility: mounted ? 'hidden' : 'visible' }">
          <template v-if="items.length > 1">
            <div
              :class="['df-carousel-item', 'prev']"
              :style="{
                position: 'absolute',
                inset: 0,
                transform: `translateX(calc(-100% - ${gap}))`,
                margin: '0',
              }"
            >
              <ItemRender :item="list[0] as CarouselItem<any>" />
            </div>
            <div
              :class="['df-carousel-item', 'current']"
              :style="{
                position: 'absolute',
                inset: 0,
                transform: 'translateX(0)',
                margin: '0',
              }"
            >
              <ItemRender :item="list[1] as CarouselItem<any>" />
            </div>
            <div
              :class="['df-carousel-item', 'next']"
              :style="{
                position: 'absolute',
                inset: 0,
                transform: `translateX(calc(100% + ${gap}))`,
                margin: '0',
              }"
            >
              <ItemRender :item="list[2] as CarouselItem<any>" />
            </div>
          </template>
          <template v-if="items.length === 1">
            <div
              :class="['df-carousel-item', 'current']"
              :style="{
                position: 'absolute',
                inset: 0,
                margin: '0',
              }"
            >
              <ItemRender :item="list[0] as CarouselItem<any>" />
            </div>
          </template>
        </div>
      </template>
      <div
        ref="elRef"
        class="df-carousel-track"
        :style="{ visibility: mounted || !ssr ? 'visible' : 'hidden' }"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
      >
        <div
          v-for="(item, idx) in list"
          :key="idx"
          :data-index="idx"
          :class="['df-carousel-item', pointer === idx ? 'current' : '', prev === idx ? 'prev' : '', next === idx ? 'next' : '']"
          :style="{ width: w ? `${w}px` : '100%' }"
        >
          <ItemRender :item="item" />
        </div>
      </div>
    </div>
    <template v-if="len > 1 && showArrow">
      <slot v-if="$slots.arrow" name="arrow" :prev="onPrev" :next="onNext"></slot>
      <ClientOnly v-else>
        <div class="df-carousel-btn-wrap absolute left-0 top-[50%] h-[64px] w-[32px] translate-y-[-50%] overflow-hidden">
          <div class="df-carousel-btn prev" @click="onPrev">
            <icon-font type="icon-icon-right-m" class="text-[24px]" />
          </div>
        </div>
        <div class="df-carousel-btn-wrap absolute right-0 top-[50%] h-[64px] w-[32px] translate-y-[-50%] overflow-hidden">
          <div class="df-carousel-btn next" @click="onNext">
            <icon-font type="icon-a-icon-right-m1" class="text-[24px]" />
          </div>
        </div>
      </ClientOnly>
    </template>
    <template v-if="len > 1 && showIndicator">
      <slot v-if="$slots.indicator" name="indicator" :move="onDotClick" :active-index="current"></slot>
      <div v-else class="df-carousel-dots" role="menu" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <span
          v-for="(item, index) in items"
          :key="index"
          :class="list[pointer]?.index === index ? 'current' : ''"
          role="menuitem"
          title=""
          @click="onDotClick(index)"
        ></span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { isNil, throttle } from 'lodash-es';
import type { PropType, VNode } from 'vue';
import type { Fn } from '~/types/fns';
import { nextFrame } from '~/utils/fns/next-frame';

interface CarouselItem<T = any> {
  index: number;
  rawItem: T;
}

const props = defineProps({
  items: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  gap: {
    type: Number,
    default: 1,
  },
  auto: {
    type: Boolean,
    default: true,
  },
  autoPlaySpeed: {
    type: Number,
    default: 3000,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  showArrow: {
    type: Boolean,
    default: true,
  },
  showIndicator: {
    type: Boolean,
    default: true,
  },
  ssr: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits<{
  (event: 'change', activeIndex: number): void;
}>();

const slots = defineSlots<{
  item: (props: { index: number; item: any }) => VNode[];
  arrow: (props: { prev: Fn<[], Promise<void>>; next: Fn<[], Promise<void>> }) => VNode[];
  indicator: (props: { move: Fn<[number], Promise<void>>; activeIndex: number }) => VNode[];
}>();

const gap = computed(() => props.gap + 'px');

const ItemRender = ({ item }: { item: CarouselItem }) =>
  slots.item({
    index: item.index,
    item: item.rawItem,
  });

const mounted = ref(false);
const isClean = ref(false);
onMounted(async () => {
  await nextTick();
  mounted.value = true;
  setTimeout(() => {
    isClean.value = true;
  }, 3000);
});

function extend<T>(items: T[]): CarouselItem<T>[] {
  const res: CarouselItem<T>[] = [];
  if (items.length === 0) {
    return res;
  }
  if (!props.loop) {
    return items.map((item, index) => ({
      index,
      rawItem: item,
    }));
  }

  res.push({
    index: items.length - 1,
    rawItem: items[items.length - 1] as any,
  });

  if (items.length === 1) {
    return res;
  }

  const _items = items.map((item, index) => ({
    index,
    rawItem: item,
  }));
  res.push(..._items);
  res.push(..._items);
  if (items.length === 2) {
    res.push(_items[0] as any);
  }
  return res;
}

const list = computed(() => extend(props.items));
const len = computed(() => props.items.length);
const pointer = ref(props.items.length > 1 && props.loop ? props.items.length + 1 : 0);
const prev = computed(() => pointer.value - 1);
const next = computed(() => pointer.value + 1);
const current = computed<any>(() => list.value[pointer.value]?.index);

const elRef = shallowRef<HTMLElement>();
const wrapRef = shallowRef<HTMLElement>();
const w = call(() => {
  const [w] = useTargetSize(wrapRef, 'self');
  watch(w, () => {
    if (!isNil(elRef.value) && isInClient()) {
      elRef.value.style.transform = `translateX(${calcX(pointer.value)}px)`;
    }
  });
  return w;
});

const calcX = (pointer: number) => {
  return -pointer * (w.value + props.gap);
};

const motion = async (pointer: number) => {
  if (!isNil(elRef.value)) {
    await Transition.play(elRef.value, {
      beforeEnter(el) {
        el.style.transition = 'all .3s ease-in-out';
      },
      enter(el) {
        el.style.transform = `translateX(${calcX(pointer)}px)`;
      },
      afterEnter(el) {
        el.style.transition = 'none';
      },
    });
  }
};

let transitioned = true;
const checkAuto = () => props.auto && len.value > 1;
const checkPrev = async (d = 0) => {
  if (pointer.value - d <= 2 && !isNil(elRef.value) && props.loop) {
    pointer.value = pointer.value + props.items.length;
    elRef.value.style.transform = `translateX(${calcX(pointer.value)}px)`;
    await nextFrame();
  }
};
const checkNext = async (d = 0) => {
  if (pointer.value + d >= list.value.length - 3 && !isNil(elRef.value) && props.loop) {
    pointer.value = pointer.value - props.items.length;
    elRef.value.style.transform = `translateX(${calcX(pointer.value)}px)`;
    await nextFrame();
  }
};
const handlePrev = async (touch = false) => {
  if (!transitioned) {
    return;
  }
  transitioned = false;

  if (!touch) {
    await checkPrev();
  }

  // 边界判断
  if (!props.loop) {
    if (pointer.value <= 0) {
      transitioned = true;
      return;
    }
  }

  pointer.value -= 1;
  emits('change', current.value);
  // console.log(list.value.length, pointer.value, current.value);
  await motion(pointer.value);
  if (touch) {
    await checkPrev();
  }

  transitioned = true;
};
const handleNext = async (touch = false) => {
  if (!transitioned) {
    return;
  }
  transitioned = false;

  if (!touch) {
    await checkNext();
  }

  // 边界判断
  if (!props.loop) {
    if (list.value.length - 1 <= pointer.value) {
      transitioned = true;
      return;
    }
  }

  pointer.value += 1;
  emits('change', current.value);
  // console.log(list.value.length, pointer.value, current.value);
  await motion(pointer.value);
  if (touch) {
    await checkNext();
  }

  transitioned = true;
};
const handleMove = async (index: number) => {
  if (current.value < index || current.value === 0) {
    const d = index - current.value;
    await checkNext(d);
    pointer.value += d;
    emits('change', current.value);
    await motion(pointer.value);
  } else if (current.value > index || current.value === len.value - 1) {
    const d = current.value - index;
    await checkPrev(d);
    pointer.value -= d;
    emits('change', current.value);
    await motion(pointer.value);
  }
};

const ticker = call(() => {
  const ticker = useTicker(() => handleNext(true), props.autoPlaySpeed);
  if (import.meta.client) {
    watch(
      () => props.auto,
      () => {
        if (!checkAuto()) {
          ticker.pause();
        } else {
          ticker.reuse();
        }
      },
      { immediate: true },
    );
  }
  return ticker;
});
const handlePause = () => {
  if (!checkAuto()) {
    return;
  }
  ticker.pause();
};
const handleReuse = () => {
  if (!checkAuto()) {
    return;
  }
  ticker.reset();
};

const onPrev = throttle(() => handlePrev());
const onNext = throttle(() => handleNext());
const onDotClick = handleMove;

let startX = 0;
let lastX = 0;
const onTouchStart = (ev: TouchEvent) => {
  if (props.items.length <= 1) {
    return;
  }
  if (checkAuto()) {
    ticker.pause();
  }
  startX = ev.touches[0]?.pageX as number;
  lastX = startX;
};
const onTouchMove = (ev: TouchEvent) => {
  ev.preventDefault(); // 防止页面滚动
  if (props.items.length <= 1) {
    return;
  }

  const pageX = ev.touches[0]?.pageX as number;

  // 边界判断
  if (!props.loop) {
    // 左边界，不能再往左滑
    if (pointer.value <= 0 && pageX > startX) {
      return;
    }
    // 右边界，不能再往右滑
    if (pointer.value >= list.value.length - 1 && startX > pageX) {
      return;
    }
  }

  const el = elRef.value as HTMLElement;
  el.style.transform = `translateX(${calcX(pointer.value) + pageX - startX}px)`;
  lastX = pageX;
};
const onTouchEnd = async () => {
  if (props.items.length <= 1) {
    return;
  }

  const d = lastX - startX;
  if (Math.abs(d) < screen.width / 6) {
    await motion(pointer.value);
    return;
  }
  if (d > 0) {
    await handlePrev(true);
  } else {
    await handleNext(true);
  }
  if (checkAuto()) {
    ticker.reuse();
  }
};

const onMouseEnter = handlePause;
const onMouseLeave = handleReuse;

defineExpose({
  prev: handlePrev,
  next: handleNext,
  move: handleMove,
  pause: handlePause,
  reuse: handleReuse,
});
</script>

<style lang="less" scoped>
.df-carousel-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .df-carousel {
    position: relative;
    height: 100%;
  }
  .df-carousel-track {
    //position: absolute;
    //top: 0;
    //left: 0;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    min-width: 100%;
  }
  .df-carousel-item {
    flex-shrink: 0;
    position: relative;
    height: 100%;
    margin-left: v-bind(gap);
    &:first-child {
      margin-left: 0;
    }
    &.prev,
    &.next {
      pointer-events: none;
    }
  }
  .df-carousel-btn {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.prev {
      margin-left: -32px;
      .anticon {
        margin-left: 50%;
      }
    }
    &.next {
      .anticon {
        transform: translateX(50%);
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 1);
    }
  }
  .df-carousel-dots {
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    margin: auto;
    span {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      padding: 0;
      margin-left: 6px;
      border: none;
      background: rgba(255, 255, 255, 0.4);
      opacity: 0.4;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.current {
        width: 28px;
        background: #fff;
        opacity: 1;
      }
    }
  }
}
</style>
