<template>
  <Exposure block-name="For You" class="for-you px-8 mobile:px-2">
    <div class="relative mx-auto w-full" style="max-width: calc(var(--max-w) + 16px)">
      <template v-if="mounted">
        <!-- header  -->
        <div class="flex items-center justify-between px-2 py-1 mobile:hidden">
          <h2 class="mb-0 text-level-1 font-semibold text-[#070B10]">For You</h2>
          <ClientOnly>
            <df-btn type="tertiary" size="small" class="preferences" @click="handleClickShowPreference">
              <icon-font type="icon-Preferences1" class="text-[16px]" />
              Recommendation Preferences
            </df-btn>
          </ClientOnly>
        </div>
        <!-- content  -->
        <template v-if="items.length > 0 || status.loading">
          <div
            v-infinite-scroll="{ infiniteLoad, keepMinHeight: true }"
            :infinite-scroll-disabled="status.finished || status.loading"
            class="for-you__content mt-2 grid gap-y-5"
          >
            <BlockCardItem
              v-for="item in items"
              :key="item.id"
              class="h-full w-full"
              :making-item="item"
              :opts="{
                pageName: PageName.HOME,
                algorithmVersion: item.recommend?.version,
                algorithmType: item.recommend?.strategy,
                blockTitle: 'For You',
              }"
              :action-opts="{ showFeedback: true }"
              :is-show-user-card="true"
            />
          </div>
          <ClientOnly>
            <div class="flex w-full justify-center">
              <a-spin v-if="!status.finished" class="py-4" :spinning="true" />
              <a-divider v-else dashed style="color: #999">This is the bottom line</a-divider>
            </div>
          </ClientOnly>
        </template>
        <!-- no data  -->
        <div v-else :class="[`absolute top-1/2 w-full -translate-y-1/2`, `flex flex-col items-center`]">
          <img :src="NO_DATA" alt="No Data" class="h-[120px] w-[120px]" />
          <div class="text-center text-level-4 text-[#999]">No Data</div>
        </div>
      </template>
      <template v-else>
        <div class="pb-10 text-center mobile:hidden">
          <a-spin :tip="'For You is loading...'" />
        </div>
        <div class="for-you__skeleton mt-2 hidden gap-y-3 mobile:grid">
          <div v-for="item in 8" :key="item" class="px-2 pb-4 pt-2">
            <DfSkeleton ratio="75%" />
            <DfSkeleton class="mt-2 h-[22px]" />
            <DfSkeleton class="mt-2 h-[24px]" />
          </div>
        </div>
      </template>
    </div>
  </Exposure>
</template>

<script setup lang="ts">
import NO_DATA from '@/assets/img/df-ip/no_data.png';
import { PageName } from '@/types/ga4';
import { storeToRefs } from 'pinia';
import { useForYou } from '~/stores/for-you';
import { usePreference } from '~/stores/preference';

const forYouService = useForYou();
const { init, infiniteLoad, updateMakingList } = forYouService;
const { items, status } = storeToRefs(forYouService);
const preference = usePreference();

const handleClickShowPreference = () => {
  preference.openPreferenceModal({
    click_source: 'for you',
  });
};

// 激活时重新获取数据
onActivated(() => {
  updateMakingList();
});

const mounted = ref(false);
onMounted(async () => {
  await init(true);
  mounted.value = true;
});

const locationHref = ref();
onMounted(() => {
  locationHref.value = window?.location?.pathname;
});
</script>

<style lang="less" scoped>
.for-you {
  margin-top: 24px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
  @media (max-width: 640px) {
    margin-top: 22px;
  }
  @media (max-width: 480px) {
    margin-top: 16px;
  }

  &__skeleton,
  &__content {
    grid-template-columns: repeat(var(--c, 5), minmax(0, 1fr));
    column-gap: var(--gap-x, 8px);
    @media (max-width: 1280px) {
      --c: 4;
    }
    @media (max-width: 860px) {
      --c: 3;
    }
    @media (max-width: 768px) {
      --gap-x: 0;
    }
    @media (max-width: 640px) {
      --c: 2;
    }
    @media (max-width: 480px) {
      --c: 1;
    }
  }
}
</style>
