<template>
  <div class="primary-banner relative h-full w-full overflow-hidden rounded" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="h-full w-full">
      <Carousel
        ref="carouselRef"
        :items="items"
        :gap="1"
        :show-arrow="false"
        :show-indicator="false"
        :auto="!isPaused"
        :auto-play-speed="5000"
        @change="onChange"
      >
        <template #item="{ item, index }">
          <a
            :href="item.link || null"
            :target="item.link ? '_blank' : '_self'"
            :data-sensors-exposure-event-name="SENSORS_EVENT.HOMEBANNEREXPOSURE"
            :data-sensors-exposure-property-content_id="item.id"
            :data-sensors-exposure-property-content_name="item.name"
            :data-sensors-exposure-property-publish_time="item.createdAt"
            :data-sensors-exposure-property-show_source="locationHref"
            data-sensors-exposure-property-belong_module="home"
            class="absolute inset-0 flex h-full w-full items-center justify-center text-[#FFF]"
            @click="handleBannerClick(item, index + 1)"
          >
            <picture class="h-full w-full object-cover">
              <source :srcset="item.cover.app" media="(max-width: 768px)" />
              <img :src="item.cover.pc" alt="" :fetchpriority="index === 0 ? 'high' : 'low'" class="h-full w-full object-cover" @error="onError" />
            </picture>
          </a>
        </template>
      </Carousel>
      <ClientOnly>
        <div
          v-if="items.length > 1"
          role="menu"
          class="banner-dots absolute bottom-[37.5px] left-4"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
        >
          <span
            v-for="(_, index) in items"
            :key="index"
            role="menuitem"
            :class="index === activeIndex ? 'active' : ''"
            @click="onDotClick(index)"
          ></span>
        </div>
      </ClientOnly>
      <ClientOnly>
        <template v-if="items.length > 1">
          <div
            :class="[
              'absolute bottom-3 right-12',
              'inline-flex h-6 w-6 items-center justify-center rounded-full mobile:hidden',
              'cursor-pointer bg-[#00000026] shadow-[0px_2.4px_9.6px_0px_#0000001A]',
            ]"
            @click="onPrev"
          >
            <icon-font type="icon-arrow-left-m" class="text-[14px] text-[#FFF]" />
          </div>
          <div
            :class="[
              'absolute bottom-3 right-4',
              'inline-flex h-6 w-6 items-center justify-center rounded-full mobile:hidden',
              'cursor-pointer bg-[#00000026] shadow-[0px_2.4px_9.6px_0px_#0000001A]',
            ]"
            @click="onNext"
          >
            <icon-font type="icon-arrow-right-m" class="text-[14px] text-[#FFF]" />
          </div>
        </template>
      </ClientOnly>
    </div>
    <div v-show="!inited" class="df-skeleton h-full w-full"></div>
  </div>
</template>

<script setup lang="ts">
import DEFAULT_IMG from '~/assets/img/creator/insight-no-img.png';
import { isNil } from 'lodash-es';
import { SENSORS_EVENT } from '~/types';
import { useEventTrack } from './hooks';
import Carousel from '@/components/common/carousel/index.vue';
import type { CarouselInstance } from '~/components/common/carousel/types';

interface Props {
  items?: any[];
  inited?: boolean;
}
withDefaults(defineProps<Props>(), {
  items: [] as any[],
});

const { handleBannerClick, locationHref } = useEventTrack();

const activeIndex = ref(0);
const onChange = (index: number) => {
  activeIndex.value = index;
};

const carouselRef = shallowRef<CarouselInstance>();
const onPrev = () => {
  carouselRef.value?.prev();
};
const onNext = () => {
  carouselRef.value?.next();
};
const onDotClick = (index: number) => {
  if (!isNil(carouselRef.value)) {
    carouselRef.value.move(index);
  }
};

const isPaused = ref(false);
const onMouseEnter = () => {
  isPaused.value = true;
};
const onMouseLeave = () => {
  isPaused.value = false;
};
const onError = (e: Event) => {
  const target = e.target as HTMLImageElement;
  if (target) {
    target.src = DEFAULT_IMG;
  }
};
</script>

<style lang="less" scoped>
.primary-banner {
  user-select: none;
  .banner-dots {
    position: absolute;
    bottom: 12px;
    display: inline-flex;
    opacity: 0.5;

    @media (min-width: 768px) {
      left: 16px;
    }
    @media (max-width: 767px) {
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
    }

    span {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      padding: 0;
      margin-left: 6px;
      border: none;
      background: #d9d9d9;
      opacity: 0.3;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.active {
        width: 28px;
        background: #fff;
        opacity: 1;
        @media (max-width: 767px) {
          background: #ff0036;
        }
      }
    }
  }
}
</style>
