<template>
  <section v-if="isShow" class="banner mb-10 px-10 pt-2 mobile:px-4">
    <div class="banner-wrap mx-auto grid w-full gap-3" style="max-width: var(--max-w)">
      <div class="primary relative">
        <PrimaryBanner :items="primaryItems" :inited="inited" />
      </div>
      <SecondaryBannerItem v-for="(item, index) in secondaryItems" :key="item.id" :item="item" :seq="index + 1" :inited="inited" class="item" />
    </div>
  </section>
</template>

<script setup lang="ts">
import PrimaryBanner from './PrimaryBanner.vue';
import SecondaryBannerItem from './SecondaryBannerItem.vue';
import { usePrimaryBanner, useSecondaryBanner } from './hooks';

const allLoaded = useState(() => false);
// const allLoaded = computed(() => primaryLoading.value && secondaryLoading.value);
const [primaryItems /* primaryLoading*/, , primaryLoad] = usePrimaryBanner();
const [secondaryItems /* secondaryLoading*/, , secondaryLoad] = useSecondaryBanner();
const isShow = computed(() => {
  return !(allLoaded.value && primaryItems.value.length === 0);
});
const loadAll = () => {
  return Promise.all([primaryLoad(), secondaryLoad()]).finally(() => {
    allLoaded.value = true;
  });
};

const mounted = ref(false);
const inited = computed(() => allLoaded.value && mounted.value);

onMounted(async () => {
  try {
    if (!allLoaded.value) {
      return;
    }
    await secondaryLoad();
  } finally {
    mounted.value = true;
  }
});

if (!allLoaded.value) {
  if (import.meta.client) {
    loadAll();
  } else {
    await loadAll();
  }
}
if (import.meta.client) {
  console.log('[home-primary-banner]', primaryItems.value);
  console.log('[home-secondary-banner]', secondaryItems.value);
}
</script>

<style scoped lang="less">
.banner {
  .banner-wrap {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    .primary {
      grid-column: span 2 / span 2;
    }
    @media (max-width: 1024px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      .item:nth-of-type(n + 8) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      .primary {
        grid-column: span 3 / span 3;
        padding-top: 56%;
      }
      :deep(.primary-banner) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    @media (max-width: 640px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      .primary {
        grid-column: span 2 / span 2;
      }
    }
  }
}
</style>
