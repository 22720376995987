<template>
  <NuxtLink
    :to="url"
    :target="target"
    :class="[
      'cursor-pointer rounded-[6px] p-2 transition-all',
      'bg-[#F6F6FA] hover:bg-[#FFF]',
      'hover:shadow-[0px_4px_32px_0px_#0000000F,0px_4px_16px_0px_#0000000D,0px_2px_8px_0px_#0000000A]',
    ]"
  >
    <div class="flex items-center gap-3 pr-3">
      <div class="h-[111px] w-[148px] flex-shrink-0 overflow-hidden rounded" style="border: 1px solid #0000000d">
        <img v-lazy="cover" alt="" class="h-full w-full object-cover" />
      </div>
      <div class="flex h-[111px] w-[calc(100%-204px)] flex-col justify-between py-1">
        <div class="flex flex-col gap-1">
          <div class="inline-flex items-center" :style="{ color: color }">
            <svg v-if="rankingNum <= 3" width="11" height="22" fill="currentColor" focusable="false">
              <use xlink:href="#icon-ranking-left" />
            </svg>
            <span class="text-[16px] font-semibold leading-[22px]">0{{ rankingNum }}</span>
            <svg v-if="rankingNum <= 3" width="11" height="22" fill="currentColor" focusable="false">
              <use xlink:href="#icon-ranking-right" />
            </svg>
          </div>
          <p class="mb-0 line-clamp-2 h-10 text-level-4 font-[500] text-[#070B10]">{{ making.title }}</p>
        </div>
        <author-user-img
          class="max-w-[100%]"
          size="16px"
          img-size="16px"
          :user-id="making.createdBy"
          :user-img="making.createdByHeadpic"
          :user-name="making.createdByName"
          :block-name="'ranking'"
          :is-show-user-card="true"
          @click.prevent=""
        />
      </div>
      <div class="flex h-[34px] w-8 flex-shrink-0 flex-col items-center gap-[2px] text-text-tertiary" @click.prevent.stop="onLiked">
        <ClientOnly>
          <CommonLikeButton :is-liked="making.userAction.like > 0" hover-color="#ff0035" :size="16" />
          <span class="text-level-8">{{ making.actionSum.like }}</span>
        </ClientOnly>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import convertTitleToSlug from '~/utils/convertTitleToSlug';
import { OBJECT_TYPE } from '~/types';

interface Props {
  rankingNum: number;
  making: any;
}

const props = withDefaults(defineProps<Props>(), {
  rankingNum: 1,
  making: {},
});

const target = isInWujie() ? '_blank' : '_self';

const color = computed(() => {
  if (props.rankingNum === 1) {
    return '#E79512';
  } else if (props.rankingNum === 2) {
    return '#4F6C82';
  } else if (props.rankingNum === 3) {
    return '#AE794B';
  } else {
    return '#8C9098';
  }
});

const making = computed(() => props.making);
const cover = computed(() => getOssProcessedImage(making.value.cover, 'm_fill,w_296,h_222', 'webp'));
const url = computed(() => `/project/${making.value.id}-${convertTitleToSlug(making.value.title)}`);

const { $client } = useNuxtApp();
const onLiked = async () => {
  if (!getUToken()) {
    $client.login();
  }

  await handleToggleAction({
    objectType: OBJECT_TYPE.MAKING,
    item: making.value,
    actionType: 'like',
    hasCookie: !!getUToken(),
  });
};
</script>

<style scoped lang="less">
.item-title {
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
</style>
