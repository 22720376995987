import type { ItemType } from '@/types';
import { CONTENT_TYPE, OpenFrom, SellTag } from '@/types';
import { type RouteLocationNormalizedLoaded } from 'vue-router';

const StatusMap: any = {
  underReview: 'Under Review',
  rejected: 'Rejected',
  removed: 'Suspended',
  '': '',
};
const auditArr = ['underReview', 'removed', 'rejected']; // 审核状态

// 处理跳转链接
export const targetLinkComputed = (makingItem: ItemType, route: RouteLocationNormalizedLoaded) => {
  const isForSale = makingItem.forSale;
  const isOffline = auditArr.includes(makingItem.hintLabel);
  let link = `/${makingItem.type}/${makingItem.id}`;
  const convertLink = `/${makingItem.type}/${makingItem.id}-${convertTitleToSlug(makingItem.title)}`;
  let isMyCreations = false;
  if (window?.location) {
    const location = window.location;
    const url = location.pathname + location.search;
    isMyCreations = url?.startsWith('/user-center');
  }
  // 个人中心+付费案例：则判断条转链接
  if (isMyCreations && isForSale) {
    if (isOffline) {
      return `/audit${link}`;
    } else {
      return convertLink;
    }
  }

  // 其他情况-直接跳转详情页
  const isFromXcs = route.query.openFrom === 'xcs';
  link = isFromXcs ? `${link}?openFrom=${OpenFrom.Xcs}` : convertLink;
  return link;
};

// 处理是否具备审核状态
export const auditComputed = (makingItem: ItemType) => {
  const isForSale = makingItem.forSale;
  const isOffline = auditArr.includes(makingItem.hintLabel);
  let isMyCreations = false;
  if (window?.location) {
    const location = window.location;
    const url = location.pathname + location.search;
    isMyCreations = url?.startsWith('/user-center');
  }
  // 付费案例-则判断审核状态
  if (isMyCreations && isForSale) {
    return isOffline;
  }
  // 非付费案例-不显示状态
  return false;
};

// 审核状态
export const auditStateComputed = (makingItem: ItemType) => {
  const isForSale = makingItem.forSale;
  const isOffline = auditArr.includes(makingItem.hintLabel);
  let isMyCreations = false;
  if (window?.location) {
    const location = window.location;
    const url = location.pathname + location.search;
    isMyCreations = url?.startsWith('/user-center');
  }
  // 付费案例-则判断审核状态
  if (isMyCreations && isForSale) {
    if (isOffline) {
      return StatusMap[makingItem.hintLabel];
    }
  }
  // 非付费案例-不显示状态
  return '';
};

// 计算数据的 computed
export const iconNumListComputed = (makingItem: ItemType) => {
  const isLiked = makingItem?.userAction?.like > 0;
  const isForsale = makingItem?.forSale;
  const list = [
    {
      icon: isLiked ? 'icon-a-30-like-2' : 'icon-a-30-like', // 暂时没用，封装到组件里了
      isTriggled: isLiked,
      count: makingItem?.actionSum?.like || 0,
      type: 'like',
    },
  ];

  // 付费项目不展示下载量
  if (!isForsale) {
    list.push({
      icon: 'icon-a-30-Download',
      isTriggled: false,
      count: makingItem?.actionSum?.download || 0,
      type: 'download',
    });
  }

  return list;
};
/**
 * 计算右上角操作项，默认只有收藏按钮，
 * 通过 showFeedback 显示屏蔽按钮
 * 通过 showRemove 显示删除按钮
 */
export const actionListComputed = (
  makingItem: ItemType,
  opts?: {
    showFavorites?: boolean;
    showFeedback?: boolean;
    showRemove?: boolean;
  },
) => {
  // console.log('makingItem :>> ', makingItem);
  const defaultOpts = { showFavorites: true, showFeedback: false, showRemove: false };
  const mergedOpts = { ...defaultOpts, ...opts };
  const actionList = [];
  const isMyFavorite = makingItem.userAction?.favorite > 0;
  if (mergedOpts.showFavorites) {
    actionList.push({
      type: 'favorite',
      icon: isMyFavorite ? 'icon-a-bookmark-fill' : 'icon-bookmark-line2',
      text: isMyFavorite ? 'Remove from Bookmarks' : 'Add to Bookmarks',
      color: isMyFavorite ? '#ff0035' : '#1a1a1a',
    });
  }
  if (mergedOpts.showFeedback) {
    actionList.push({ type: 'feedback', icon: 'icon-unlike', text: 'See fewer templates like this' });
  }
  if (mergedOpts.showRemove) {
    actionList.push({ type: 'remove', icon: '', text: 'Remove' });
  }
  return actionList;
};

export const tagMap = {
  [SellTag.BestSeller]: {
    tagName: SellTag.BestSeller,
    tagBgColor: '#FA2730',
    tagAngleColor: '#942F19', // 废弃
  },
  [SellTag.New]: {
    tagName: SellTag.New,
    tagBgColor: '#00C767',
    tagAngleColor: '#0E800E',
  },
  [SellTag.Exclusive]: {
    tagName: SellTag.Exclusive,
    tagBgColor: '#2661F1',
    tagAngleColor: '#23549D',
  },
  [SellTag.Bundle]: {
    tagName: SellTag.Bundle,
    tagBgColor: '#F2AB51',
    tagAngleColor: '#B87514',
  },
};

// 获取售卖标签的 computed
export const tagComputed = (makingItem: ItemType, isUserCenter: boolean) => {
  const tag = makingItem.sellTag;
  const makingType = makingItem.type;
  // 个人中心
  // console.log('isUserCenter :>> ', isUserCenter);
  if (isUserCenter) {
    return {
      tagName: 'Premium',
      tagBgColor: '#F2AB51',
      tagAngleColor: '#926118',
    };
  }

  // xthings
  if (makingType === CONTENT_TYPE.XTHINGS) {
    return {
      tagName: '',
      tagBgColor: '',
      tagAngleColor: '',
    };
  }

  // 无tag
  if (!tag) {
    // bundle (bundle有可能没有tag)
    if (makingType === CONTENT_TYPE.BUNDLE) {
      return tagMap[SellTag.Bundle];
    }
    return {
      tagName: '',
      tagBgColor: '',
      tagAngleColor: '',
    };
  }

  return tagMap[tag];
};

// 计算价格信息的 computed
export const priceComputed = (makingItem: ItemType) => {
  const discount = makingItem.discount;
  const hasDiscount = makingItem.hasDiscount;

  // 判断是否真的有折扣
  const hasDisc = makingItem.hasDiscount && discount && Object.keys(discount).length !== 0;

  // 原价
  const origPrice = +makingItem.origPrice;
  if (!hasDiscount || !discount || Object.keys(discount).length === 0) {
    return {
      hasDiscount: hasDisc,
      origPrice: `$${origPrice.toFixed(2)}`,
      disCountPrice: `$${0}`,
      savePrice: `$${0}`,
    };
  }
  // 折后价格
  const disCountPrice = discount.disPrice.toFixed(2);
  // 折扣百分比
  const discountPercent = discount.discountPercent;
  // 节约了多少
  const savePrice = (origPrice - discount.disPrice).toFixed(2);
  return {
    hasDiscount: hasDisc,
    origPrice: `$${origPrice.toFixed(2)}`,
    disCountPrice: `$${disCountPrice}`,
    discountPercent: `-${discountPercent}%`,
    savePrice: `$${savePrice}`,
  };
};
