import { getHomePrimaryBannerApi, getHomeSecondaryBannerApi } from '~/api';

export function useEventTrack() {
  const { sensorsBannerClickEvent, traceBannerClick } = useSensors();
  const handleBannerClick = (banner: any, seq: number) => {
    traceBannerClick({
      content_id: banner.id,
      content_name: banner.name,
      page_name: 'homepage',
      block_name: 'homepage_main',
      sequence: seq,
    });

    sensorsBannerClickEvent({
      content_id: banner.id,
      content_name: banner.name,
      belong_page: window.location.href,
      belong_module: 'home',
    });
  };

  const locationHref = ref('');
  onMounted(() => {
    locationHref.value = window.location.href;
  });
  return {
    handleBannerClick,
    locationHref,
  };
}

export function usePrimaryBanner() {
  const loading = useState(() => false);
  const items = useState(() => [] as any[]);
  const load = async () => {
    try {
      loading.value = true;
      const res = await getHomePrimaryBannerApi.fetch();
      const list: any[] = (res.data ?? []).slice(0, 10);
      items.value = list.map((item) => ({
        id: item.id,
        name: item.name,
        link: item.extra?.link,
        cover: {
          pc: item.extra?.cover_pc,
          app: item.extra?.cover_app,
        },
      }));
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };
  return [items, loading, load] as const;
}

export function useSecondaryBanner() {
  const loading = useState(() => false);
  const items = useState(() => [] as any[]);
  const load = async () => {
    try {
      loading.value = true;
      const res = await getHomeSecondaryBannerApi.fetch({
        current: 1,
        pageSize: 8,
      });
      const list: any[] = (res.data?.list ?? []).slice(0, 8);
      items.value = list.map((item) => ({
        id: item.id,
        title: item.title,
        link: parsePath(item),
        icon: {
          url: item.cornerMarkerUrl,
          name: item.cornerMarkerName,
        },
        cover: {
          isGif: item.cover?.endsWith('.gif'),
          original: item.cover,
          preview: getOssProcessedImage(item.cover || '', 'm_fill,w_474,h_356', 'webp'),
        },
      }));
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };
  const parsePath = (item: any) => {
    if (!item.linkValue) {
      return `/project/${item.defaultMakingId}-${convertTitleToSlug(item.title)}`;
    } else if (item.linkType !== 'link') {
      return `/project/${item.linkValue}-${convertTitleToSlug(item.title)}`;
    } else if (item.linkValue?.startsWith('http') && isSameSiteURL(item.linkValue)) {
      return getSameSiteURLPath(item.linkValue);
    } else {
      return item.linkValue;
    }
  };

  return [items, loading, load] as const;
}
