<template>
  <div class="block-card-item-wrapper h-full w-full">
    <NuxtLink :to="nuxtLinkUrl">
      <div class="block-card-item group/block-card flex h-full w-full rounded-md bg-[#fff] px-2 pb-3 pt-2">
        <div
          :id="`blockSingle-${makingItem.id}`"
          :key="makingItem.id"
          v-viewer="[observerCardItem, { threshold: 0.8 }]"
          class="group/single-item block-single-item relative h-full w-full"
          :data-sensors-exposure-property-content_id="makingItem.id"
          :data-sensors-exposure-property-scene_name="mergedOpts.sceneName"
          :data-sensors-exposure-property-page_name="mergedOpts.pageName"
          :data-sensors-exposure-property-block_name="mergedOpts.blockTitle"
          :data-sensors-exposure-property-algorithm_type="makingItem.recommend?.strategy ?? null"
          :data-sensors-exposure-property-algorithm_version="makingItem.recommend?.version ?? null"
          :data-sensors-exposure-property-search_result_ranking="mergedOpts.searchResultRanking"
          :data-sensors-exposure-property-search_keyword="mergedOpts.keyword"
          @click="handleCheckMakings"
        >
          <div class="block-top-wrap group/cover relative w-full overflow-hidden rounded" :title="makingItem.title">
            <!-- 图片和蒙层 -->
            <RatioBox :padding-top="'75%'">
              <CardImg class="h-full w-full overflow-hidden rounded" :making-item="makingItem" :disabled-lazy-load="disabledLazyLoad" />
            </RatioBox>

            <!-- 右上角操作项 -->
            <ClientOnly>
              <div
                class="block-card-right-extra absolute right-2 top-2 z-10 hidden justify-end group-hover/single-item:flex"
                :class="{ '!flex': isFocus }"
              >
                <slot name="rightExtra" :card-info="makingItem">
                  <CommonMoreActions ref="moreActionsRef" :action-list="actionList" @command="postCommonActions" />
                </slot>
              </div>
            </ClientOnly>

            <!-- 价格 -->
            <div v-if="isForSale" class="absolute bottom-0 left-0 z-20 w-full">
              <div class="relative h-7 w-full">
                <div
                  :class="'absolute right-0 top-0 h-7 rounded-tl-[4px] bg-uni-0/90 px-2 text-level-7 font-semibold leading-7 text-[#fff] transition-opacity duration-200 group-hover/single-item:opacity-0'"
                >
                  {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
                </div>
                <div :class="'price-wrap absolute left-0 top-[100%] flex h-7 w-full items-center justify-between bg-uni-0/90 px-2'">
                  <div class="inline-flex items-center gap-1 text-[#fff]">
                    <div class="text-level-7 font-semibold">
                      {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
                    </div>
                    <div v-if="isDiscount" class="text-level-7 text-[#fff] line-through opacity-40">
                      {{ priceInfo.origPrice }}
                    </div>
                  </div>
                  <div v-if="!isXThings" class="ml-1 flex gap-1 text-level-7 font-semibold text-[#FFF]">
                    <span class="text-status-alert">Credits</span>
                    <span>{{ makingItem.credits ?? 0 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 付费tag 和 title -->
          <div class="mt-2 flex items-center gap-1">
            <div v-if="isForSale && sellTagInfo?.tagName && feedbackStatus === 'deactive'" class="inline-flex flex-shrink-0">
              <span
                class="h-[18px] rounded-sm px-1 text-[12px] font-semibold leading-[18px] text-[#fff]"
                :style="{ background: sellTagInfo.tagBgColor }"
              >
                {{ sellTagInfo.tagName }}
              </span>
            </div>
            <h2 class="mb-0 truncate text-level-4 font-medium text-text-primary" :title="makingItem.title">
              {{ makingItem.title }}
            </h2>
          </div>

          <div class="user-wrap block-bottom-wrap mt-1 flex h-5 items-center justify-between" @click.prevent="">
            <!-- user -->
            <AuthorUserImg
              v-if="isShowAuthor"
              size="16px"
              img-size="16px"
              :user-id="makingItem.createdBy"
              :user-img="makingItem.createdByHeadpic"
              :user-name="makingItem.createdByName"
              :block-id="mergedOpts.blockId"
              :block-name="mergedOpts.blockTitle"
              :link-target="linkTarget"
              :is-show-user-card="isShowUserCard"
              @click.prevent
            />
            <!-- 时间、审核tag -->
            <div v-else class="flex flex-1 items-center justify-between gap-[8px]">
              <ClientOnly>
                <div class="one-line-ellipsis flex h-[22px] items-center text-[12px] leading-[22px] text-text-tertiary">
                  {{ moment(makingItem?.publishedAt * 1000).format('YYYY/MM/DD') }}
                </div>
              </ClientOnly>
              <span
                v-if="isAudit"
                :class="[
                  'flex items-center rounded px-2 text-[12px] font-semibold pad:px-0',
                  {
                    'bg-[#FF7C23]/10 text-[#FF7C23]': BlockCardStatus.Todo === auditState,
                    'bg-[#FF4159]/10 text-[#FF4159]': BlockCardStatus.Rejected === auditState,
                    'bg-[#9E9E9E]/10 text-[#9E9E9E]': BlockCardStatus.Removed === auditState,
                  },
                ]"
              >
                {{ auditState }}
              </span>
            </div>

            <!-- 点赞和下载 -->
            <div v-if="!isAudit" class="flex gap-2">
              <div
                v-for="(item, index) in iconNumList"
                :key="index"
                class="flex items-center gap-0.5 text-text-tertiary"
                :title="item.type"
                @click.prevent.stop="postCommonActions(item.type)"
              >
                <ClientOnly>
                  <template v-if="item.type === 'like'">
                    <CommonLikeButton :is-liked="item.isTriggled" :size="12" hover-color="#ff0035" />
                  </template>
                  <template v-else>
                    <icon-font
                      :type="item.icon"
                      class="text-[12px]"
                      :class="[
                        {
                          'text-text-tertiary hover:text-[#ff0035]': item.type === 'like' && !item.isTriggled,
                          'text-[#ff0035]': item.type === 'like' && item.isTriggled,
                        },
                      ]"
                    />
                  </template>
                </ClientOnly>
                <ClientOnly>
                  <span class="whitespace-nowrap pt-[1px] text-level-7">{{ item.count }}</span>
                </ClientOnly>
              </div>
            </div>
          </div>

          <Feedback v-model:status="feedbackStatus" :item="makingItem" />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import { BlockCardStatus, type ItemType, OBJECT_TYPE, OpenFrom } from '@/types';
import moment from 'moment';
import CardImg from './CardImg.vue';
import Feedback from './Feedback.vue';
import { actionListComputed, auditComputed, auditStateComputed, iconNumListComputed, priceComputed, tagComputed, targetLinkComputed } from './hooks';
import { SceneName, PageName } from '@/types/ga4';

type OptsType = {
  keyword?: string; // 搜索关键词
  algorithmVersion?: string; // 算法版本
  algorithmType?: string; // 算法类型
  searchResultRanking?: number; // 搜索结果排名
  pageName?: PageName; // 当前页面名称
  sceneName?: SceneName; // 当前场景名称
  blockTitle?: string; // 栏目名称
  blockId?: number; // 栏目id
};
type PropsType = {
  makingItem: ItemType;
  actionOpts?: { showFavorites?: boolean; showFeedback?: boolean; showRemove?: boolean }; // 右上角操作项
  isShowAuthor?: boolean; // 是否展示作者
  isSearch?: boolean; // 是否为搜索结果
  opts?: OptsType; // 搜索/埋点相关参数
  disabledLazyLoad?: boolean; // 禁用封面的懒加载，活动页运营要求
  linkTarget?: '_self' | '_blank'; // 链接打开方式
  isUserCenter?: boolean; // 是否在用户中心
  isShowUserCard?: boolean; // 是否展示用户卡片
};
type EmitsType = {
  (e: 'toggleActions', type: string): void;
  (e: 'clickItem'): void; // for search result page
  (e: 'toggleLike'): void;
};

const emits = defineEmits<EmitsType>();
const props = withDefaults(defineProps<PropsType>(), {
  isShowAuthor: true,
  onlyImg: false,
  isSearch: false,
  opts: () => ({}),
  actionOpts: () => ({}),
  linkTarget: '_self',
  isUserCenter: false,
  isShowUserCard: false,
});

const { ga4ContentClickEvent, sensorForYouSeeFewerClick, ga4CaseExposure } = useSensors();
const router = useRouter();
const route = useRoute();
const { $client } = useNuxtApp();

const ITEM_TYPE = ref(props.makingItem.type);
const feedbackStatus = ref<any>('deactive');

// 合并默认opts和传入的opts
const mergedOpts = computed(() => ({
  keyword: '',
  algorithmVersion: '',
  algorithmType: '',
  searchResultRanking: 0,
  sceneName: SceneName.ATOMM,
  pageName: PageName.HOME,
  blockTitle: '',
  blockId: undefined,
  ...props.opts,
}));
// 数据统计
const iconNumList = computed(() => iconNumListComputed(props.makingItem));
// 右上角操作项
const actionList = computed(() => actionListComputed(props.makingItem, props.actionOpts));
// 是否付费
const isForSale = computed(() => props.makingItem.forSale);
// 是否 xthings
const isXThings = computed(() => props.makingItem.type === 'xthings');
// 是否有折扣
const isDiscount = computed(() => props.makingItem.hasDiscount);
// 折扣信息
const priceInfo = computed(() => priceComputed(props.makingItem));
// 付费标签
const sellTagInfo = computed(() => tagComputed(props.makingItem, props.isUserCenter));
// 跳转地址
const targetLink = computed(() => targetLinkComputed(props.makingItem, route));
// NuxtLink的to属性(用于预加载或阻止跳转)
const nuxtLinkUrl = computed(() => {
  if (isAudit.value || props.linkTarget === '_blank' || isInWujie()) {
    return '';
  }
  return targetLink.value;
});
// 是否处于审核状态
const isAudit = computed(() => auditComputed(props.makingItem));
// 审核状态
const auditState = computed(() => auditStateComputed(props.makingItem));

// 调用公共的操作方法
const postCommonActions = async (actionType: string) => {
  if (actionType === 'feedback') {
    feedbackStatus.value = 'selecting';
    const item = props.makingItem;
    sensorForYouSeeFewerClick({
      content_id: item.id + '',
      content_type: item.forSale ? 'paid' : 'free',
      content_name: item.title,
      service_type: item.type,
      element_name: 'See fewer projects like this',
    });
  } else {
    const cookie = useMiddleCookie('utoken');
    const hasCookie = Boolean(cookie.value);
    if (!hasCookie) {
      goToLogin();
      return;
    }
    switch (actionType) {
      case 'like':
        await handleToggleAction({
          objectType: OBJECT_TYPE.MAKING,
          item: props.makingItem,
          actionType,
          hasCookie,
          scene_name: mergedOpts.value.sceneName,
          page_name: mergedOpts.value.pageName,
          block_name: mergedOpts.value.blockTitle,
        });
        emits('toggleLike');
        break;
      case 'favorite':
        if (props.makingItem.userAction?.favorite > 0) {
          Modal.confirm({
            title: 'Are you sure you want to remove this template?',
            cancelText: 'Cancel',
            okText: 'Ok',
            closable: true,
            width: 550,
            onOk: async () => {
              await handleToggleAction({
                objectType: OBJECT_TYPE.MAKING,
                item: props.makingItem,
                actionType,
                hasCookie,
                scene_name: mergedOpts.value.sceneName,
                page_name: mergedOpts.value.pageName,
                block_name: mergedOpts.value.blockTitle,
              });
              message.success('Removed successfully.', 1);
              emits('toggleActions', actionType);
            },
          });
        } else {
          await handleToggleAction({
            objectType: OBJECT_TYPE.MAKING,
            item: props.makingItem,
            actionType,
            hasCookie,
            scene_name: mergedOpts.value.sceneName,
            page_name: mergedOpts.value.pageName,
            block_name: mergedOpts.value.blockTitle,
          });
          message.success('Successfully added to Bookmarks.', 1);
          emits('toggleActions', actionType);
        }
        break;
      case 'remove':
        emits('toggleActions', actionType);
        break;
      default:
        break;
    }
  }
};

const moreActionsRef = ref();
const isFocus = computed(() => moreActionsRef.value?.open);

// 登录
function goToLogin() {
  $client.login();
}

// 点击卡片
const handleCheckMakings = () => {
  const { makingItem } = props;
  // 内容点击上报
  ga4ContentClickEvent({
    content_id: makingItem.id,
    author_id: makingItem.createdBy,
    link_url: `${window.location.origin}/${ITEM_TYPE.value}/${makingItem.id}`,
    scene_name: mergedOpts.value.sceneName,
    page_name: mergedOpts.value.pageName,
    block_name: mergedOpts.value.blockTitle,
    search_keyword: mergedOpts.value.keyword,
    algorithm_type: makingItem.recommend?.strategy,
    algorithm_version: makingItem.recommend?.version,
  });

  // 搜索的结果点击上报
  if (props.isSearch) {
    emits('clickItem');
  }
  // 在wujie中时，新窗口打开
  if (isInWujie()) {
    const link = `${targetLink.value}?openFrom=${OpenFrom.StudioHome}`;
    // console.log('link :>> ', link);
    createAlink(link, '_blank');
    return;
  }
  // 审核/_blank时，新窗口打开
  if (isAudit.value || props.linkTarget === '_blank') {
    window.open(targetLink.value, '_blank');
  } else {
    // 设置神策属性
    window?.df_shen?.setParams({
      latest_page_name: locationHref.value,
      latest_block_name: props.opts?.blockTitle,
      latest_keyword: props.opts?.keyword,
      latest_scene_name: String(makingItem.id),
      latest_contentid_algorithm_version: props.opts?.algorithmVersion,
      latest_contentid_algorithm_type: props.opts?.algorithmType,
    });

    router.push({
      path: targetLink.value,
      query: route.query.openFrom === 'xcs' ? { openFrom: 'xcs' } : {},
    });
  }
};

// 卡片曝光
const targetIsVisible = shallowRef(false);
function observerCardItem([entry]: IntersectionObserverEntry[]) {
  targetIsVisible.value = entry?.isIntersecting || false;
  if (targetIsVisible.value) {
    ga4CaseExposure({
      content_id: props.makingItem.id,
      scene_name: props.opts?.sceneName,
      page_name: props.opts?.pageName,
      block_name: props.opts?.blockTitle,
      algorithm_type: props.makingItem.recommend?.strategy,
      algorithm_version: props.makingItem.recommend?.version,
      search_result_ranking: props.opts?.searchResultRanking,
      search_keyword: props.opts?.keyword,
    });
  }
}

const locationHref = ref();
onMounted(() => {
  locationHref.value = window?.location?.pathname?.split('-')[0];
});
</script>

<style lang="less" scoped>
.block-card-item {
  transition-property: box-shadow;
  transition-duration: 0.5s;
  :deep(&:hover) {
    .img-wrapper::before {
      opacity: 1;
    }
  }
}
.price-wrap {
  transition-property: transform;
  transition-duration: 0.2s;
}
.block-card-item-wrapper:hover {
  .block-card-item {
    box-shadow:
      0px 0px 2px 0px rgba(0, 0, 0, 0.08),
      0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  }
  .price-wrap {
    transform: translateY(-100%);
  }
}
</style>
