<template>
  <NuxtLink
    :to="`/collection/${makingItem.id}`"
    :target="isInWujie() ? '_blank' : '_self'"
    :draggable="draggable"
    class="block-collection-item group/cover rounded"
    :data-sensors-exposure-event-name="SENSORS_EVENT.COLLECTION_BLOCK_EXPOSURE"
    :data-sensors-exposure-property-collection_id="makingItem.id"
    :data-sensors-exposure-property-collection_name="makingItem.title"
    :data-sensors-exposure-property-show_source="source"
    :data-sensors-exposure-property-block_id="blockId"
    :data-sensors-exposure-property-block_name="blockTitle"
    @click="handleSensorCollectionBlock(makingItem)"
  >
    <div class="relative h-full w-full overflow-hidden rounded border-[1px] border-solid border-[#0000001a]">
      <!-- 操作项 -->
      <ClientOnly>
        <div class="absolute right-2 top-2 z-10 hidden group-hover/cover:block mobile:block">
          <a-popover
            v-model:open="toggle"
            trigger="hover"
            placement="bottom"
            :z-index="100"
            :arrow="false"
            :overlay-inner-style="{ padding: '4px', minWidth: makingItem.userAction?.favorite == 1 ? '220px' : '170px' }"
            :auto-adjust-overflow="true"
          >
            <icon-font
              type="icon-more-fill"
              class="flex h-6 w-6 rotate-90 items-center justify-center rounded-full bg-[#fff] text-text-primary opacity-90"
              @click.prevent.stop
            />
            <template #content>
              <div
                class="flex cursor-pointer flex-nowrap gap-2 rounded-lg px-3 py-2.5 text-text-primary hover:bg-background-2"
                @click.stop="handleFavorite"
              >
                <icon-font
                  :type="makingItem.userAction?.favorite == 1 ? 'icon-a-bookmark-fill' : 'icon-bookmark-line2'"
                  :class="['text-[20px]', makingItem.userAction?.favorite == 1 ? 'text-red-default' : '']"
                />
                <span class="whitespace-nowrap">
                  {{ makingItem.userAction?.favorite == 1 ? 'Remove from Bookmarks' : 'Add to Bookmarks' }}
                </span>
              </div>
            </template>
          </a-popover>
        </div>
      </ClientOnly>
      <!-- 封面 -->
      <RatioBox padding-top="54.66%" :draggable="draggable" class="cover relative w-full">
        <img-group v-if="multiple" :imgs="makingItem.makingCovers || []" :draggable="draggable" class="cover h-full w-full" />
        <img v-else v-lazy="getOssProcessedImage(makingItem.cover, 'm_fill,w_740,h_404', 'webp')" class="h-full w-full object-cover" alt="" />
      </RatioBox>

      <div class="flex w-full flex-col justify-center gap-1 px-3 pb-4 pt-2">
        <div class="line-clamp-1 whitespace-pre-line break-words text-level-4 font-[500] text-text-primary" :title="makingItem.title">
          {{ makingItem.title }}
        </div>
        <div class="my-0 flex h-4 items-center py-0.5 font-sans text-level-8 text-text-tertiary">
          <ClientOnly>
            <icon-font type="icon-Collection1" class="mr-1 text-[12px]" />
            <span class="mr-3 leading-[1]">{{ formatNumberWithUnit(quantity) }} template{{ makingItem.quantity > 1 ? 's' : '' }}</span>
            <icon-font type="icon-eye-line" class="mr-1 text-[12px]" />
            <span class="leading-[1]">{{ formatNumberWithUnit(viewCnt) }} view{{ viewCnt > 1 ? 's' : '' }}</span>
          </ClientOnly>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { OBJECT_TYPE, SENSORS_EVENT } from '@/types';
// import type { ItemType } from '@/types/common';
import { doActionApi } from '@/api';

import { formatNumberWithUnit } from '~/utils/fns/number';
// import { isEmpty } from 'lodash-es';

type ItemType = {
  makingCovers: string[];
  cover: string;
  title: string;
  id: number;
  quantity: number;
  createdByDeleted: boolean;
  createdByName: string;
  userAction: {
    favorite: number;
  };
  actionSum: {
    view: number;
  };
};
type PropsType = {
  makingItem: ItemType;
  actions?: string[];
  blockTitle?: string;
  blockId?: number | undefined;
  source?: string | number; // 埋点，来源
  draggable?: boolean; // 是否可拖拽
  multiple?: boolean; // 是否显示为图片组
};
type EmitsType = {
  (e: 'changeFavorite', type: 'remove' | 'add'): void;
};

const emits = defineEmits<EmitsType>();
const props = withDefaults(defineProps<PropsType>(), {
  draggable: false,
  multiple: true,
  actions: () => [],
  blockTitle: '',
  blockId: undefined,
  source: '',
});

const item = computed(() => props.makingItem);
const quantity = computed(() => item.value.quantity || item.value.quantityShow || 0);
const viewCnt = computed(() => item.value.actionSum?.view || 0);

const handleFavorite = async () => {
  const item = props.makingItem;
  if (item.userAction.favorite === 1) {
    handleSecondConfirm({
      apiFun: doActionApi,
      apiParams: {
        objectType: OBJECT_TYPE.COLLECTION,
        objectId: item.id,
        contentType: 'favorite',
        revoke: true,
      },
      type: 'removeCollection',
      cb: () => {
        emits('changeFavorite', 'remove');
        setTimeout(() => {
          item.userAction.favorite = 0;
        }, 200);
        handleSensorCollectContent(item, true);
      },
    });
  } else {
    const { data } = await doActionApi('favorite', {
      objectType: OBJECT_TYPE.COLLECTION,
      objectId: item.id,
      contentType: 'favorite',
      revoke: false,
    });
    if (data.value?.code === 0) {
      message.success('Successfully added to Bookmarks');
      emits('changeFavorite', 'add');

      setTimeout(() => {
        item.userAction.favorite = 1;
      }, 200);
    }
    handleSensorCollectContent(item, false);
  }
  toggle.value = false;
};

const { sensorCollectionBlockClick, sensorCollectContentEvent } = useSensors();
const handleSensorCollectionBlock = (makingItem: ItemType) => {
  sensorCollectionBlockClick({
    collection_id: makingItem.id,
    collection_name: makingItem.title,
    clickSource: props.source,
  });
};
const handleSensorCollectContent = (item: any, revoke: boolean) => {
  sensorCollectContentEvent({
    entry: 'block',
    content_id: item.id,
    content_name: item.title,
    service_type: 'collection',
    action_revoke: revoke,
  });
};

const toggle = ref(false);
</script>

<style lang="less" scoped>
.block-collection-item {
  display: block;
  width: 100%;
  background: #fff;
  transition-property: box-shadow;
  transition-duration: 0.2s;
  &:hover {
    box-shadow:
      0 4px 32px 0 #0000000f,
      0 4px 16px 0 #0000000d,
      0 2px 8px 0 #0000000a;
  }
  .cover {
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;
    }
  }
  &:hover .cover::after {
    opacity: 0.2;
  }
}
</style>
