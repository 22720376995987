/**
 * GA4埋点事件
 */
export enum GA4Event {
  // 注册&登录
  LOGIN_POP_SHOW = 'loginPopShow', // 登录&注册弹窗展示
  LOGIN_POP_CLOSE = 'loginPopClose', // 登录&注册弹窗关闭
  DESIGN_FIND_LOGIN = 'designLogin', // 登录成功后
  NEW_USER_SIDE_WIDGET_CLICK = 'newUserSideWidgeClick', // 注册引导挂件点击

  // 首页相关
  RANKING_EXPOSURE = 'rankingExposure', // 首页案例排行曝光
  CONTESTS_EXPOSURE = 'contestsExposure', // 首页活动模块曝光

  // 详情页相关
  CONTENT_DETAIL_VIEW = 'contentDetailView', // 内容详情浏览
  COMPATIBLE_MACHINE_GUIDELINE_CLICK = 'compatibleMachineGuidelineClick', // 推荐机型指引点击
  OPEN_IN_STUDIO_CLICK = 'openInStudioClick', // open in studio按钮点击
  DOWNLOAD_CLICK = 'downloadClick', // 下载按钮点击
  DOWN_POP_SUCCESS = 'downpopSuccess', // 下载弹窗展示
  DETAIL_IN_CONTEST_CLICK = 'detailInContestClick', // 详情页活动引导条see more按钮点击
  TRY_AI_MAKE_CLICK = 'tryAImakeClick', // 详情页AImake引导条【try AImake】按钮点击
  CONTENT_DETAIL_TAB_CLICK = 'contentDetailTabClick', // 详情页内tab点击
  DETAIL_PAGE_CREDITS_REDEEM = 'detailPageCreditsRedeem', // 案例详情页付费案例credits兑换
  BUY_AND_USE_CLICK = 'buyAndUseClick', // Buy&Use按钮点击

  // 用户交互
  BOOKMARK = 'bookMark', // 收藏⭐️
  CONTENT_LIKE = 'contentLike', // 点赞👍
  FOLLOW = 'follow', // 关注
  SHARE_CLICK = 'shareClick', // 分享点击

  // 曝光事件
  TEMPLATE_EXPOSURE = 'templateExposure', // 内容曝光(原contentExposure)
  COLLECTION_BLOCK_EXPOSURE = 'collectionBlockExposure', // 合集卡片曝光
  BANNER_EXPOSURE = 'bannerExposure', // banner曝光

  // 点击事件
  COLLECTION_BLOCK_CLICK = 'collectionBlockClick', // 合集卡片点击
  SORT_BY_CLICK = 'sortByClick', // 排序按钮点击
  TEMPLATE_CLICK = 'templateClick', // 案例卡片点击(原contentClick)
  SEE_MORE_CLICK = 'seeMoreClick', // "See More"按钮点击
  FILTER_FINISH = 'filterFinish', // 筛选完成
  BANNER_CLICK = 'bannerClick', // banner点击
  SELECT_TAG_CLICK = 'SelectTagClick', // templates聚合页标签点击
  RANKING_CLICK = 'rankingClick', // 首页案例排行点击
  CONTESTS_CLICK = 'contestsClick', // 首页活动模块点击

  // Credits核心埋点
  CREDITS_POP_SHOW = 'creditsPopShow', // credits充值弹窗弹出（曝光）
  CREDITS_POP_CLICK = 'creditsPopClick', // credits充值弹窗点击
  CREDITS_PAYMENT_CLICK = 'creditsPaymentClick', // credits充值支付点击
  CREDITS_PAYMENT_SUCCESS = 'creditsPaymentSuccess', // credits充值成功 （需与后端实际支付成功状态关联）

  // 个人中心/创作者中心
  CREATER_CENTER_MENU_CLICK = 'creatercenterMenuClick', // 创作者中心菜单栏元素点击
  PROFILE_MENU_CLICK = 'profileMenuClick', // profile页菜单栏点击
  PROFILE_USER_CARD_CLICK = 'profileUserCardClick', // profile页个人信息卡片点击

  // 编辑器
  MAKING_OPTION_EXPOSURE = 'makingOptionExposure', // How to create面板曝光
  MAKING_OPTION_CLICK = 'makingOptionClick', // How to create面板点击
  PUBLISH_BOTTON_CLICK = 'PublishBottonClick', // 导航栏publish按钮点击
  PUBLISH_EDITOR_BOTTOM_CLICK = 'publishEditorBottomClick', // 内容上传编辑器表单底部按钮点击
  PUBLISH_FINISH = 'publishFinish', // 发布提交
  PUBLISH_EDITOR_CLICK = 'publishEditorClick', // 内容上传编辑器内元素点击

  // 消息中心
  // ...

  // 搜推相关
  SEE_FEWER_CLICK = 'seefewerClick', // 推荐内容屏蔽原因点击
  SEARCH_RESULT = 'searchResult', // 返回搜索结果
  SEARCH_FILTERS_CLICK = 'searchFiltersClick', // 搜索左侧筛选项点击
  SEARCH_RESULT_CLICK = 'searchResultClick', // 搜索结果点击
  SEARCH_RESULT_SATISFACTION_EXPOSURE = 'searchresultsatisfactyExposure', // 搜索结果是否满意曝光
  SEARCH_RESULT_SATISFACTION_CLICK = 'searchresultsatisfactyClick', // 搜索结果是否满意点击选择

  // 导航栏相关
  NAV_CLICK = 'navClick', // 导航栏点击
  PUBLISH_BUTTON_CLICK = 'PublishBottonClick', // 导航栏publish按钮点击
}

/**
 * GA4埋点事件参数
 */
export type downpopSuccessType = {
  content_id: number; // 内容id
  file_name: string; // 文件名
  entry: string; // 入口
  is_free: boolean; // 是否免费
  latest_page_name?: string; // 最新页面名称
  latest_block_name?: string; // 最新区块名称
  latest_keyword?: string; // 最新关键词
  latest_scene_name?: string; // 最新场景名称
  latest_contentid_algorithm_version?: string; // 最新内容ID算法版本
  latest_contentid_algorithm_type?: string; // 最新内容ID算法类型
};

export type openInStudioType = {
  file_name: string; // 文件名
  content_id: number; // 内容id
  is_free: boolean; // 是否免费
  scene_name: SceneName; // 当前场景名称 editors/atomm/studio
  click_position?: 'up' | 'bottom' | 'in_file'; // 点击位置
  latest_page_name?: string; // 最新页面名称
  latest_block_name?: string; // 最新区块名称
  latest_keyword?: string; // 最新关键词
  latest_scene_name?: string; // 最新场景名称
  latest_contentid_algorithm_version?: string; // 最新内容ID算法版本
  latest_contentid_algorithm_type?: string; // 最新内容ID算法类型
};

export type downloadClickType = {
  file_name: string; // 文件名
  content_id: number; // 内容id
  is_free: boolean; // 是否免费
  click_position?: 'up' | 'bottom' | 'in_file'; // 点击位置
  latest_page_name?: string; // 最新页面名称
  latest_block_name?: string; // 最新区块名称
  latest_keyword?: string; // 最新关键词
  latest_scene_name?: string; // 最新场景名称
  latest_contentid_algorithm_version?: string; // 最新内容ID算法版本
  latest_contentid_algorithm_type?: string; // 最新内容ID算法类型
};

// 当前场景名称
export enum SceneName {
  EDITORS = 'editors',
  ATOMM = 'atomm',
  STUDIO = 'studio',
}
// 当前页面名称
export enum PageName {
  HOME = 'home', // 首页
  TEMPLATES = 'templates', // templates聚合页
  AIMAKES = 'aimakes', // AImake聚合页
  PROFILE = 'profile', // profile页
  SEARCH_PANEL = 'searchPanel', // 搜索页
  SEARCH_RESULT = 'searchresult', // 搜索结果页
  ACTIVITY = 'activity', // 活动投稿页
  USERCARD = 'usercard', // 用户卡片中
  BLOG = 'blog', // 博客页
  TOPIC = 'topic', // 话题页
  USER_CENTER = 'user-center', // 用户中心页
  COLLECTION = 'collection', // 合集页
  DETAILS_PAGE = 'detailspage', // 项目详情页
  PAY = 'pay', // 订单页
}

// 内容点击事件
export type ContentClickEventType = {
  content_id: number; // 内容id
  author_id: number; // 作者id
  link_url: string; // 跳转页面url
  scene_name?: SceneName; // 当前场景名称 editors/atomm/studio
  page_name?: PageName; // 当前页面名称
  block_name?: string; // 栏目名称
  algorithm_type?: string; // 算法类型
  algorithm_version?: string; // 算法版本
  search_keyword?: string; // 搜索关键词
};

// 关注事件
export type FollowEventType = {
  follower?: string; // 被关注人名称
  follower_community_id?: number; // 被关注人id
  status?: string; // follow/unfollow（关注/取关）
  scene_name?: SceneName; // 当前场景名称 editors/atomm/studio
  page_name?: PageName; // 当前页面名称
};

// 分享事件
export type ShareEventType = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  publish_time: number | string; // 发布时间
  author_name: string; // 作者名称
  scene_name: SceneName; // 当前场景名称 editors/atomm/studio
  page_name: PageName; // 当前页面名称
  block_name: string; // 栏目名称
  service_type: string; // 服务类型
  share_type: string; // 分享社媒类型
};

// 案例曝光
export type TemplateExposureType = {
  content_id: number; // 内容id
  scene_name?: SceneName; // 当前场景名称 editors/atomm/studio
  page_name?: PageName; // 当前页面名称
  block_name?: string; // 栏目名称
  algorithm_type?: string; // 算法类型
  algorithm_version?: string; // 算法版本
  search_result_ranking?: number; // 搜索结果排名
  search_keyword?: string; // 搜索关键词
};

// 点赞事件
export type ContentLikeEventType = {
  content_id: number; // 内容id
  content_name?: string; // 内容名称
  author_name?: string; // 作者名称
  scene_name?: SceneName; // 当前场景名称 editors/atomm/studio
  page_name?: PageName; // 当前页面名称
  block_name?: string; // 栏目名称
  action_revoke?: boolean; // 操作取消
  service_type?: string; // 内容类型
};

// 收藏事件
export type BookmarkEventType = {
  content_id: number; // 内容id
  content_name?: string; // 内容名称
  author_name?: string; // 作者名称
  scene_name?: SceneName; // 当前场景名称 editors/atomm/studio
  page_name?: PageName; // 当前页面名称
  block_name?: string; // 栏目名称
  action_revoke?: boolean; // 操作取消
  service_type?: string; // 内容类型
};
