<template>
  <div class="secondary-banner-item group/item relative">
    <div class="relative w-full overflow-hidden rounded pt-[75%]">
      <NuxtLink
        v-show="inited"
        :to="item.link"
        :target="item.link.startsWith('http') ? '_blank' : '_self'"
        class="absolute inset-0 block cursor-pointer"
        @click="onClick()"
      >
        <img :src="item.cover.preview || DEFAULT_IMG" :alt="item.title" class="absolute left-0 top-0 h-full w-full object-cover" @error="onError" />
        <img
          v-if="item.cover.isGif"
          :src="item.cover.original || item.cover.preview || DEFAULT_IMG"
          :alt="item.title"
          loading="lazy"
          fetchpriority="low"
          class="absolute left-0 top-0 h-full w-full object-cover opacity-0 group-hover/item:opacity-100"
          @error="onError"
        />
        <ClientOnly>
          <div
            class="absolute bottom-0 left-0 flex h-full w-full flex-col justify-end px-3 pb-3 opacity-0 transition-all group-hover/item:opacity-100 mobile:hidden"
          >
            <div class="absolute inset-0 z-[1] bg-[#000] opacity-30"></div>
            <div class="absolute bottom-0 left-0 z-[1] h-[30%] w-full bg-[linear-gradient(0deg,#000000_0%,rgba(0,0,0,0)_100%)] opacity-60"></div>
            <p class="relative z-[2] mb-0 line-clamp-2 text-[14px] font-[500] leading-[18px] text-background-1">{{ item.title }}</p>
          </div>
        </ClientOnly>
        <ClientOnly v-if="item.icon?.url">
          <div v-if="!item.icon.name" class="absolute left-2 top-2 z-[10] h-6 w-6 rounded-full shadow-[0px_8px_24px_0px_#0000001A]">
            <img :src="item.icon?.url" alt="" class="h-full w-full rounded-full object-cover" @error="onError" />
          </div>
          <a-tooltip v-else placement="right">
            <template #title>
              <span>{{ item.icon.name }}</span>
            </template>
            <div class="absolute left-2 top-2 z-[10] h-6 w-6 rounded-full shadow-[0px_8px_24px_0px_#0000001A]">
              <img :src="item.icon?.url" alt="" class="h-full w-full rounded-full object-cover" @error="onError" />
            </div>
          </a-tooltip>
        </ClientOnly>
      </NuxtLink>
    </div>
    <p class="footer-title mb-0 mt-1 line-clamp-2 hidden h-8 text-level-8 font-[500] text-text-secondary">{{ item.title }}</p>
    <DfSkeleton v-show="!inited" class="absolute inset-0 h-full w-full" />
  </div>
</template>
<script setup lang="ts">
import DEFAULT_IMG from 'assets/img/creator/insight-no-img.png';

interface Props {
  item: any;
  seq: number;
  inited?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  item: {} as any,
  seq: 0,
});

const { traceBannerClick } = useSensors();
const onClick = () => {
  traceBannerClick({
    content_id: props.item.id,
    content_name: props.item.name,
    page_name: 'homepage',
    block_name: 'homepage_sub',
    sequence: props.seq,
  });
};

const onError = (e: Event) => {
  const target = e.target as HTMLImageElement;
  if (target) {
    target.src = DEFAULT_IMG;
  }
};
</script>

<style scoped lang="less">
.secondary-banner-item {
  .footer-title {
    @media (max-width: 640px) {
      display: -webkit-box;
    }
  }
}
</style>
