// 神策自定义事件名
export enum SENSORS_EVENT {
  FOLLOW = 'follow',
  CONTENT_LIKE = 'contentLike',
  ADD_COMMENT = 'addComment',
  COMMENT_FINISH = 'commentFinish',
  CONTENT_DETAIL_TEMPLATE_CLICK = 'contentDetailTemplateClick',

  // 卡片
  COLLECT_CONTENT = 'collectContent',
  CONTENT_CLICK = 'contentClick',
  CONTENT_EXPOSURE = 'contentExposure',
  CASE_EXPOSURE = 'caseExposure',

  // 搜索 和 all页面
  SEARCH_CLICK = 'searchClick',
  SEARCH_RESULT = 'searchResult',
  SEARCH_RESULT_CLICK = 'searchResultClick',
  SEARCH_RESULT_SATISFACTY_EXPOSURE = 'searchresultsatisfactyExposure',
  SEARCH_RESULT_SATISFACTY_CLICK = 'searchresultsatisfactyClick',
  SEARCH_DOWN_BOX_CLICK = 'searchdownboxClick',
  SEARCH_FILTERS_CLICK = 'searchFiltersClick',
  SEARCH_RESULT_FIRST_CLICK = 'searchResultfirstClick',
  SELECTWORDCLICK = 'selectWordClick',
  RANKCLICK = 'rankClick',
  ALLCOLLECTIONCLICK = 'allCollectionClick',
  FILTERCHOOSE = 'filterChoose',

  // 详情页
  CONTENT_DETAIL_FINISH = 'contentlViewFinish',
  CONTENT_DETAIL_TAB_CLICK = 'contentDetailTabClick',
  CONTENT_DETAIL_STEP_CLICK = 'contentDetailStepClick',
  GENERATE_SIMILAR_CLICK = 'generateSimilarClick',
  TRY_AI_MAKE_CLICK = 'tryAImakeClick',
  ACTIVITY_ENTRY_CLICK = 'detailInContestClick',
  CONTENT_DETAIL_VIEW = 'contentDetailView',
  DETAIL_COMPATIBLE_GUIDE_CLICK = 'compatibleMachineGuidelineClick',

  SHARE_MY_MAKING_CLICK = 'shareMyMakingClick',
  NEW_PROJECT_CLICK = 'newProjectClick',
  My_DRAFT_CLICK = 'myDraftClick',
  MAKING_OPTION_CLICK = 'makingOptionClick',
  SAVE_MAKING_CLICK = 'saveProjectClick',
  PREVIEW_MAKING_CLICK = 'previewMakingClick',
  PUBLISH_MAKING_CLICK = 'publishMakingClick',
  CONTENT_MAKING = 'contentMaking',
  BANNER_CLICK = 'bannerClick',
  LIBRARY_UPLOAD_FILE = 'libraryUploadFile',
  LIBRARY_EMBED_VIDEO = 'libraryEmbedVideo',
  DRAG_FILE = 'dragFile',
  GUIDELINE_CLICK = 'guidelineClick',
  LIBRARY_CONFIRM = 'libraryConfirm',
  REWARD_ITEM_CLICK = 'rewardItemClick',
  SHOW_XCC_DIALOG = 'showXccDialog',
  XCC_DIALOG_CLICK_BIND = 'xccDialogClickBind',
  XCC_DIALOG_CLICK_FINISHED = 'xccDialogClickFinished',
  CLOSE_XCC_DIALOG = 'closeXccDialog',
  REWARD_REDEEM_SUCCESS = 'rewardRedeemSuccess',
  SHOP_XTOOL = 'shopXTool',
  EARN_XTOOL_POINTS_CLICK = 'earnXToolPointsClick',
  CREATE_YOUTS = 'createYours',
  PAYMENT_CONFIRM = 'paymentConfirm',
  DOWNLOAD_XCS = 'downloadXCS',
  XARTUSAGE_CLICK = 'xartusageClick',
  TRY_CLICK = 'tryClick',
  INSTALL_POP = 'installPop',
  DOWN_POP_SUCCESS = 'downpopSuccess',
  BILLLING_DETAIL = 'billingDetail',
  START_SHOPPING = 'startShopping',
  DOWN_POP_LIKE = 'downpopLike',
  DOWN_POP_COLLECT = 'downpopCollect',
  DOWN_POP_SHARE = 'downpopShare',
  DOWN_POP_COMMENT = 'downpopComment',
  JOIN_SUPPORT_CLICK = 'joinsupportClick',
  ASK_CLICK = 'askClick',
  XARTWORKSCLICK = 'xArtworksClick',
  FEATURECLICK = 'featuedClick',
  ENTRIESCLICK = 'entriesClick',
  ENTERNOW = 'enterNow',
  SEEMORECLICK = 'seemoreClick',
  HOLLAENTERNOW = 'halloEnterNow',
  PREVIEWPAGE = 'previewPage',
  OPENINBROWSER = 'openinBrowser',
  COPYCODE = 'copyCode',
  CHECKFILE = 'checkFile',
  LOTTERY = 'lottery',
  SHARENOW = 'shareNow',
  ACTIVITYDOWNLOD = 'activityDownload',
  REPORTCLICK = 'reportClick',
  CONFIRMCLICK = 'confirmClick',
  RECOMMENDCLICK = 'recommendClick',
  SUBMITREPORT = 'submitReport',
  LICENSEAGREE = 'licenseAgree',
  REMIXCLICK = 'remixClick',
  HOMEBANNEREXPOSURE = 'homeBannerExposure',
  HOMEPAGECOLLECTIONCLICK = 'homepagecollectionClick',
  FILTERCLICK = 'filterClick',
  FILTERFINISH = 'filterFinish',
  COLLECTIONCLICK = 'collectionClick',
  CONFIGURATIONCLICK = 'configurationClick',
  BUTTONCLICK = 'buttonClick',
  SEEMORE = 'seeMore',
  FEEDBACK_CLICK = 'feedbackClick',
  MESSAGE_SCENTER_CLICK = 'messagescenterClick',
  MESSAGE_CENTER_MESSAGE_CLICK = 'messagescentermessageClick',
  COMMENT_CLICK = 'commentClick',
  // PRIME_PROJECT_CLICK = 'primeProjectClick',
  DRAFT_CLICK = 'myDraftcontentClick',
  PRIME_LIMIT_CLICK = 'primeprojectLimitvisit',
  BOM_SHOP = 'BoMshop',
  BOM_EXPOSURE = 'BoMexposure',

  // 登录
  LOGIN_POP_SHOW = 'loginPopshow', // 登录弹窗弹出
  LOGIN_POP_CLOSE = 'loginPopclose', // 登录弹窗关闭
  DESIGN_FIND_LOGIN = 'designfindLogin', // 登录成功后

  // 活动
  AI_MAKE_ACTIVITY_PAGEVIEW = 'aimakeactivityPageview',
  AI_MAKE_ACTIVITY_PAGE_CLICK = 'aimakeactivityPageClick',
  AI_MAKE_ACTIVITY_POP_CLICK = 'aimakeactivityPopClick',

  // 创作者中心
  CREATOR_CENTER_ENTRY_CLICK = 'creatercenterenterClick',
  CREATOR_CENTER_MENU_CLICK = 'creatercenterMenuClick',
  CREATOR_CENTER_DASHBOARD_CLICK = 'creatercenterDashboardClick',
  CREATOR_SET_PAYOUTS_FORM_EXPOSURE = 'creatercenterpayoutsformExposure',
  CREATOR_SET_PAYOUTS_FORM_SUBMIT = 'creatercenterpayoutsformSubmit',
  CREATOR_APPLY_EXPOSURE = 'primecreatorformExposure',
  CREATOR_APPLY_SUBMIT = 'primecreatorformSubmit',
  CREATOR_APPLY_RESULT = 'primecreatorapplyResult',
  CREATOR_CENTER_OTHER_PAYOUT_CLICK = 'creatercenterotherpayoutClick',
  CREATOR_CENTER_SELECT_PAYOUT_CLICK = 'creatercenterselectpayoutClick',
  CREATOR_CENTER_OTHER_PAYOUTS_FORM_SUBMIT = 'creatercenterotherpayoutsformSubmit',
  CREATOR_CENTER_STORE_CLICK = 'creatercenterStoreClick',

  // 创作者招募广告
  BECOME_CREATOR_POP_SHOW = 'becomeCreatorPopshow',

  // 话题
  TOPIC_SHARE_CLICK = 'shareClick',

  FOR_YOU_TAG_CHOOSE = 'foryoutagChoose',
  FOR_YOU_TAG_POP_SHOW = 'foryoutagPopShow',
  FOR_YOU_TAG_CLOSE = 'foryoutagClose',
  FOR_YOU_SEE_FEWER_CLICK = 'foryouseefewerClick',

  EDITOR_CLICK = 'editorClick',
  EDITOR_POP_SHOW = 'editorPopShow',
  EDITOR_POP_CLOSE = 'editorPopClose',
  EDITOR_DOCU_UPLOAD = 'editordocusuccessUpload',
  EDITOR_PIC_UPLOAD = 'editorpicturesuccessUpload',
  FILE_UPLOAD_ERROR = 'fileUploadError',

  // Subscription
  SUBSCRIPTION_OR_PAY_ORIGINAL_POP_SHOW = 'subscriptionorpayoriginalPopShow', // 订阅/单购弹窗弹出
  SUBSCRIPTION_OR_PAY_ORIGINAL_CLICK = 'subscriptionorpayoriginalClick', // 订阅/单购弹窗点击
  SUBSCRIPTION_FREE_TRIAL_POP_SHOW = 'subscriptionfreetrialPopShow', // 订购试用弹窗弹出
  SUBSCRIPTION_FREE_TRIAL_CLICK = 'subscriptionfreetrialClick', // 订购试用弹窗弹点击
  SUBSCRIPTION_CONTENT_DETAIL_CLICK = 'subscriptioncontentdetailClick', // 内容详情页中订阅点击
  SUBSCRIPTION_PERSONAL_CENTER_CLICK = 'subscriptionpersonalcenterClick', // 个人中心订阅点击
  SUBSCRIPTION_PRICING_PAGE_VIEW = 'subscriptionpricingPageView', // Pricing页曝光
  SUBSCRIPTION_PRICING_CLICK = 'subscriptionpricingClick', // Pricing页点击
  SUBSCRIPTION_ACTIVA_SUCCESSL_POP_SHOW = 'subscriptionactivasuccesslPopShow', // 开通成功弹窗弹出
  SUBSCRIPTION_ACTIVA_SUCCESSL_CLICK = 'subscriptionactivasuccesslClick', // 开通成功弹窗点击
  SUBSCRIPTION_ACTIVA_SUCCESSL_CLOSE = 'subscriptionactivasuccesslClose', // 开通成功弹窗关闭

  // Creator
  CREATOR_RECRUITMENT_PAGE_VIEW = 'creatorrecruitmentPageView', // 创作者招募落地页曝光
  CREATOR_RECRUITMENT_CLICK = 'creatorrecruitmentClick', // 创作者招募落地页点击
  BECOME_CREATOR_CLICK = 'becomeCreatorClick', // 成为创作者条幅点击
  BECOME_CREATOR_CLOSE_REASON = 'becomeCreatorCloseReason', // 成为创作者条幅点击
  PREMIUM_CREATOR_CICK = 'premiumCreatorCick', // 付费创作者标识点击

  // Credit
  CREDITS_DETAIL = 'creditsDetail', // 案例详情页付费图纸积分兑换
  CREDITS_POP_SHOW = 'creditsPopShow', // 积分充值弹窗弹出
  CREDITS_POP_CLICK = 'creditsPopClick', // 积分充值弹窗点击
  CREDITS_PAYMENT_CLICK = 'creditsPaymentClick', // 积分充值支付点击
  CREDITS_PAYMENT_SUCCESS = 'creditspaymentSuccess', // 积分充值成功
  CREDITS_RESULT_CLICK = 'creditsresultClick', // 积分兑换结果页点击
  // 个人中心
  USER_CENTER_ORDER_CLICK = 'usercentermyordersClick', // 个人中心订单页点击
  USER_CENTER_CREDITS_CLICK = 'usercreaditsClick', // 个人中心credits点击
  USER_CENTER_CREDITS_LIST_CLICK = 'usercentermycreditsClick', // 个人积分历史筛选
  USER_CENTER_CREDITS_EXPOSURE = 'usercentermycreditsExposure', // 个人中心积分曝光

  BLOCK_EXPOSURE_STAY_TIME = 'blockexposureStaytime',

  // 合集
  COLLECTION_BLOCK_EXPOSURE = 'collectionBlockExposure', // 合集卡片曝光
  COLLECTION_BLOCK_CLICK = 'collectionBlockClick', // 合集卡片点击

  NEW_USER_SIDE_WIDGE_CLICK = 'NewUserSideWidgeClick', // 注册引导挂件点击
}

export type SensorsContentClickEventType = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  content_tag: string[]; // 内容标签
  publish_time: number; // 发布时间
  author_name: string; // 作者名称
  author_id: number; // 作者id
  service_type: string; // 所属板块
  link_url: string; // 跳转页面url
  block_name?: string; // 栏目名称
  block_id?: number; // 栏目id
  content_type?: string; // paid/free-付费/免费
  algorithm_type?: string; // 算法类型
  algorithm_version?: string; // 算法版本
};

export type SensorsAddCommentEventType = {
  comment_id: number; // 评论id
  comment_content: string; // 评论内容
  comment_time: number; // 评论时间
};

export type SensorsCommentFinishEventType = {
  comment_id: number; // 评论id
  comment_content: string; // 评论内容
  comment_time: number; // 评论时间
};

export type SensorsCollectContentEventType = {
  content_id: number; // 内容id
  entry: string; // 入口 up right corner,bottom,dialog。右上角、底部、弹窗
  content_name: string; // 内容名称
  action_revoke: boolean; // 动作
  service_type: string; // 所属板块
  author_name?: string; // 作者名称
};

export type SensorsCaseExposure = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  content_show_type: string; // 内容展示类型
  content_tag: string; // 内容标签
  publish_time: number; // 发布时间
  author_name: string; // 作者名称
  show_source: string; // 曝光来源
  block_id: number; // 栏目id
  block_name: string; // 栏目名称
  service_type: string;
  content_type: string;
  algorithm_type: any;
  algorithm_version: any;
};

export type SensorsContentLikeType = {
  content_id: number; // 内容id
  entry: string; // 入口 up right corner,bottom,dialog。右上角、底部、弹窗
  content_name: number; // 内容名称
  action_revoke: boolean; // 动作
  service_type: string; // 所属板块
  author_name: string; // 作者名称
};
export type SensorsContentExposureEventType = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  content_show_type: string; // 内容展示类型
  content_tag: string[]; // 内容标签
  publish_time: number; // 发布时间
  author_name: string; // 作者名称
  show_source: string; // 曝光来源
};

export type SensorsFollowEventType = {
  follower?: string; // 被关注人名称
  follower_community_id?: number; // 被关注人id
  content_id?: number; // 内容id
  content_name?: number; // 内容名称
  action_revoke?: boolean; // 动作
  service_type?: string; // 所属板块
  author_name?: string; // 作者名称
  status?: string; // 关注/取关
  source?: string; // 关注来源,homepage/search/case（主页精选作者/搜索/案例）
};

export type SensorsShareMyMakingClickEventType = {
  login_status: boolean; // 是否登录
};

export type SensorsMakingOptionClickEventType = {
  // service_type: string; // 业务类型
  // is_draft: boolean;
  // content_type: 'paid' | 'free' | 'sale';
  never_show: boolean;
  click_element: string;
};

export type SensorSaveMakingClickEventType = {
  is_success: boolean; // 是否保存成功
  fail_reason: string; // 失败原因
};

export type SensorsPreviewMakingClickEventType = {
  is_success: boolean; // 是否预览成功
  making_type: string; // 发布类型
  content_type: 'paid' | 'free' | 'sale';
};

export type SensorsPublishMakingClickEventType = {
  is_success: boolean; // 是否发布成功
  fail_reason: string; // 失败原因
  making_type: string; // 发布类型
  content_type: 'paid' | 'free' | 'sale';
};

export type SensorsContentMakingType = {
  xtool_community_id: number; // 社区id
  content_id: number; // 内容id
  content_name: string; // 内容名称
  content_tag: string[]; // 内容标签
  author_name: string; // 作者姓名
  machine: string; // 机器名称
  material: string; // 材料
  service_type: string; // 业务类型
  status: string; // 发布状态 publish/save
  content_type: 'paid' | 'free' | 'sale';
};

export type SensorsBannerClickEventType = {
  // content_show_type: string; // 内容展示类型
  // content_tag: string; // 内容标签
  belong_page: string; // 所在页面
  belong_module: string; // 所在模块
  // show_source: string; // 曝光来源
  content_id?: number; // 内容id
  content_name?: string; // 内容名称
};

export type SensorsLibraryUploadFileType = {
  select_count: number; // 选择文件数量
  is_success: boolean; // 是否成功
  fail_reason: string; // 失败原因
};

export type SensorsLibraryEmbedVideoType = {
  video_url: string; // 视频地址
};

export type SensorsDragFileType = {
  type: string; // 文件类型
};

export type SensorsGuidelineClickEventType = {
  type: string; // guideline类型
};

export type SensorsLibraryConfirmType = {
  select_count: number; // 选择文件数量
};

export type SensorsSubmitReportClickEventType = {
  is_success: boolean; // 是否成功 boolean
  is_picture: boolean; // 是否有图片 boolean
  reportContent: string; // 文案内容 string
  reportTags: string | any; // 举报标签 string/json
};

export type SensorsCollectionClickEventType = {
  collection_id?: number; // 合集ID
  collection_name?: string; // 合集名称
  block_id?: number; // 模块id
  block_name?: string; // 模块名称
};

export type SensorsButtonClickEventType = {
  element_id?: string; // 元素内容
  element_name?: string; // 元素内容
};

export type SensorsSeeMoreEventType = {
  block_id: number; // 栏目id
  block_name: string; // 栏目类型
  clickSource: string | number; // 点击来源
};

export type SensorsBillingDetailType = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  author_id: number; // 作者id
  author_name: string; // 作者名称
  commodity_price: number; // 商品原价
  discount_price: number; // 折扣价
  click_positon: string; // 购买来源:顶部/底部
  latest_page_name?: string; // 最近一次页面来源
  latest_block_name?: string; // 最近一次栏目来源
  latest_keyword?: string; // 最近一次关键词来源
  latest_scene_name?: string; // 最近一次案例id来源
  latest_contentid_algorithm_version?: string; // 最近一次算法版本
  latest_contentid_algorithm_type?: string; // 最近一次案例算法类型
};

export type SensorsCreateYoursType = {
  entry: string; // 入口
  file_name: string; // 文件名称
  content_id?: number; // 内容id
  content_name?: string; // 内容名称
  service_type?: string; // 所属内容类型:howtos/inspiration
  content_type?: string; // 案例类型:paid/free（ps：付费案例/免费案例）
  is_xcs?: boolean; // 是否是xcs环境
  identity_pro?: 'Pro' | 'Pro+' | 'Free'; // 身份: Pro/Pro+/Free
  latest_page_name?: string; // 最近一次页面来源
  latest_block_name?: string; // 最近一次栏目来源
  latest_keyword?: string; // 最近一次关键词来源
  latest_scene_name?: string; // 最近一次案例id来源
  latest_contentid_algorithm_version?: string; // 最近一次算法版本
  latest_contentid_algorithm_type?: string; // 最近一次案例算法类型
};

export type SensorsConfigurationClickEventType = {
  block_id: number; // 栏目id
  block_name: string; // 栏目类型
};

export type SensorsFeedbackClickEventType = {
  feedback_content: string;
  feedback_type: string;
};

export type SensorsHomepagecollectionClickEventType = {
  element_type: string; // 元素类型
  element_id: number; // 元素id
  element_name: string; // 元素短文案名称
};

export type SensorsCommentClickEventType = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  content_tag: string[]; // 内容标签
  block_id?: number; // 栏目id
  block_name?: string; // 栏目名称
  publish_time: number; // 发布时间
  author_name: string; // 作者名称
  author_id: number; // 作者id
  service_type: string; // 所属板块
  elementId: string; // 按钮名称
};

export type SensorsDraftItem = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  content_tag: string[]; // 内容标签
  publish_time: number; // 发布时间
  author_name: string; // 作者名称
  author_id: number; // 作者id
  service_type: string; // 所属板块
};

export type SensorsBomShop = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  content_tag: string[]; // 内容标签
  author_name: string; // 作者名称
  author_id: number; // 作者id
  content_type: 'paid' | 'free'; // 内容类型
  service_type: 'xthings' | 'howtos' | 'inspiration' | 'designs'; // 所属板块
  element_name: string; // 商品名称/变体名称
};

export type SensorsBomExposure = {
  content_id: number; // 内容id
  content_name: string; // 内容名称
  content_tag: string[]; // 内容标签
  author_name: string; // 作者名称
  author_id: number; // 作者id
  content_type: 'paid' | 'free'; // 内容类型
  service_type: 'xthings' | 'howtos' | 'inspiration' | 'designs'; // 所属板块
  element_name: string; // 商品名称/变体名称
};

export type SensorsAllFilterChoose = {
  element_name: string; // 筛选内容
  status: string; // 勾选/取消勾选
};

export type SensorsCreatorCenterMenuClick = {
  element_name: string; // 菜单名称
};

export type SensorsCreatorCenterDashboardClick = {
  element_name: string; // 元素名称
};

export type SensorsTopicShareClick = {
  element_name: string; // 社媒名称
};

export type SensorCreatorSetpayoutsSubmitType = {
  status: 1 | 0; // 状态
};

export type SensorCreatorApplySubmitType = {
  status: 1 | 0; // 状态
};

export type SensorCreatorApplyResultType = {
  status: any; // 状态Pending/Enabled/Rejected（审核中/通过/拒绝）
};

export type SensorForYouTagChooseType = {
  element_name: string; // dog,cat,S1（多选标签时以逗号隔开）
  click_source: string; // 点击位置
};

export type SensorForYouSeeFewerType = {
  content_id: string;
  content_name: string;
  content_type: string; // free/paid
  service_type: string; // 'project' | 'design' | 'bundle' | 'xthings';
  element_name: string; // 内容名称
};

export type SensorEditorClickType = {
  element_name: string; // 点击的元素名称
  sevice_type: string; // 'project' | 'design' | 'bundle'; // 内容类型
  content_type: string; // 'free' | 'sale';
  content_id: number; // 内容id
  status?: number; // 水印状态（裁剪）
  source?: string; // 文件：Select from XCS”/“Select or Upload Files，图片来源：upload/drag
  element_content?: string; // 元素内容
};

export type SensorXcsImportFileType = {
  source: string; // designs
  content_id: string; // 内容id
};

export type SensorSubscriptionOrPayOriginalPopShowType = {
  sevice_type?: 'Project' | 'Design' | 'Bundle';
  source: 'case' | 'AImake';
  content_id?: number; // 内容id
  content_name?: string; // 内容名称
};

export type SensorSubscriptionOrPayOriginalClickType = {
  sevice_type?: 'Project' | 'Design' | 'Bundle';
  source: 'case' | 'AImake';
  content_id?: number; // 内容id
  content_name?: string; // 内容名称
  element_name?: 'Upgrade' | 'Pay at original price';
};
export type SensorSubscriptionFreeTrialPopShowType = {
  sevice_type?: 'Project' | 'Design' | 'Bundle';
  source: 'case' | 'AImake';
  content_id?: number; // 内容id
  content_name?: string; // 内容名称
  second_source?: 'Buy&Use' | 'Upgrade' | 'Renewal'; // 弹窗曝光二级来源
};
export type SensorSubscriptionFreeTrialClickType = {
  sevice_type?: 'Project' | 'Design' | 'Bundle';
  source: 'case' | 'AImake';
  content_id?: number; // 内容id
  content_name?: string; // 内容名称
  second_source?: 'Buy&Use' | 'Upgrade' | 'Renewal'; // 弹窗二级来源
  element_name?: 'Pro' | 'Pro+'; // 弹窗内元素名称
};

export type SensorSubscriptionContentDetailClickType = {
  sevice_type?: 'Project' | 'Design' | 'Bundle';
  source: 'case' | 'AImake';
  content_id?: number; // 内容id
  content_name?: string; // 内容名称
  second_source?: 'Buy&Use' | 'Upgrade' | 'Renewal'; // 弹窗二级来源
  element_name?: 'Upgrade' | 'Go Renewal'; // 详情页内元素名称
};
export type SensorSubscriptionPersonalCenterClickType = {
  element_name: string; // 详情页内元素名称
};
export type SensorSubscriptionPricingClickType = {
  element_name: 'Get Pro+' | 'Get Pro'; // 详情页内元素名称
};

export type SensorSubscriptionBillingDetailType = {
  content_id?: number; // 内容id
  content_name?: string; // 内容名称
  keyword?: string; // 关键词
  element_id?:
    | 'View'
    | 'Edit Price'
    | 'Price Adjustment'
    | 'searchresult'
    | 'Price Adjustment OK'
    | 'Discount Tab'
    | 'Discount Tips Get'
    | 'Create discount event bottom'
    | 'Create discount event top'
    | 'Add Assets'
    | 'Discount delete sale inner'
    | 'Discount delete sale exter'
    | 'Discount edit sale inner'
    | 'Discount edit sale exter'
    | 'Discount Add Products'
    | 'Discount Remove Products';
};

export enum SENSORS_EVENT {
  BUY_MATERIAL = 'buyMaterial', // 购买耗材
  OPENIN_XCS = 'openInXcs', // 打开xcs
  HOME_DEVICE_CLICK = 'machineChoice', // 首页设备型号点击
  HOME_MATERIAL_TYPE_CLICK = 'materialTypeChoice', // 首页耗材类别点击
  WEB_CLICK = '$WebClick', // 点击事件
  BLOCK_ITEM_CLICK = 'listingClick', // 点击耗材详情
  DETAIL_PAGE_VIEW = 'listingView', // 详情页曝光
  DETAIL_POP_MACHINE_CLICK = 'buyMachine', // 详情页推荐设备点击
  DETAIL_MATRIX_ITEM_CLICK = 'matrixClick', // 详情页矩阵参数电机
  HEADER_STORE_CLICK = 'storeClick',
  MACHINE_COLLECTION_PAGE_VIEW = 'machineCollectionView', // 设备合集页曝光
  MATERIAL_COLLECTION_PAGE_VIEW = 'materialCollectionView', // 耗材类型合集页曝光
  SEARCH_FILTER_APPLY_CLICK = 'filterApply', // 搜索结果页筛选点击
  CUSTOMIZE_HOME_START_CLICK = 'materialTest',
  BUTTON_CLICK = 'buttonClick',
  CUSTOMIZE_UPLOAD_RESULT = 'uploadRecognize',
}

export type SensorsSearchClickType = {
  keyword: string; // 搜索关键词
};

export type SensorsSearchResultType = {
  keyword: string; // 搜索关键词
  has_result: boolean; // 是否有搜索结果
  search_result_num: number; // 搜索结果数量
};

export type SensorsSearchResultClickType = {
  keyword: string; // 搜索关键词
  content_id: string; // 耗材 id
};

export type SensorsBuyMaterialType = {
  extId: string; // 设备类型
  power?: string; // 功率
  processingMode?: string; // 加工模式
};

export type SensorsOpenInXcsType = {
  extId: string; // 设备类型
  power: string; // 功率
  processingMode: string; // 加工模式
};

// 点击首页设备型号
export type SensorsClickHomeDeviceType = {
  type: string; // 设备型号
  name: string; // 设备名称
};

// 点击首页设备型号
export type SensorsClickHomeMaterialTypeType = {
  type: string; // 耗材类别
  name: string; // 耗材名称
};

export type SensorWebClickType = {
  'id': string; // 元素id
};

export type SensorsClickBlockItemType = {
  content_id: string; // 耗材 id
  page: string; // 页面
};

export type SensorsDetailPopMachineClickType = {
  type: string; // 设备型号
  name: string; // 设备名称
  url: string; // 设备详情页地址
};

export type SensorsDetailMatrixItemClickType = {
  category_name: string; // 类别名称
  category_id: string; // 类别id
  machine: string; // 设备id
  laser_power: number; // 激光功率
  material_id: string; // 材料id
  processing: string; // 加工方式
};

export type SensorsCollectionPageViewType = {
  name: string; // 名称
};

export type SensorsSearchFilterApplyClickType = {
  machineName: string; // 设备名称
  materialType: string; // 耗材类型
  processingHead: string; // 加工头
};

export type SensorsDetailPageViewType = {
  material_id: string; // 耗材 id
  source: string; // 来源
};
