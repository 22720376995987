<template>
  <section v-if="isShow" class="featured-collections mb-6 px-10 mobile:hidden mobile:px-4">
    <div class="mx-auto w-full" style="max-width: var(--max-w)">
      <div v-show="inited">
        <div class="mb-4 flex items-center justify-between py-1">
          <h2 class="mb-0 text-level-1 font-semibold text-[#070B10]">Featured Collections</h2>
          <NuxtLink to="/collection/allcollections" class="inline-flex items-center gap-1 text-[#070B10]" @click="onSeeMoreClick">
            <span class="text-level-4 font-[500]">More</span>
            <ClientOnly>
              <icon-font type="icon-arrow-right-m" class="text-[16px]" />
            </ClientOnly>
          </NuxtLink>
        </div>
        <Lane :items="items" :gap="24" :column="4">
          <template #item="{ item, index }">
            <template v-if="index < SHOW_LEN">
              <block-collection-item :making-item="item" :draggable="false" source="homepage" />
              <div class="mx-auto h-2 w-[90%] rounded-b bg-[#E7E9ED]"></div>
              <div class="mx-auto h-2 w-[85%] rounded-b bg-[#F1F2F5]"></div>
            </template>
            <NuxtLink v-else to="/collection/allcollections" class="block h-full w-full" @click="onSeeMoreClick">
              <div
                class="see-more-card relative h-[calc(100%-16px)] w-full overflow-hidden rounded"
                :style="{
                  backgroundImage: `url(${SEE_MORE_PNG})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }"
              >
                <div class="absolute inset-0 bg-[#000]/70 backdrop-blur-[12px]"></div>
                <ClientOnly>
                  <df-btn type="filled" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">See more</df-btn>
                </ClientOnly>
              </div>
              <div class="mx-auto h-2 w-[90%] rounded-b bg-[#E7E9ED]"></div>
              <div class="mx-auto h-2 w-[85%] rounded-b bg-[#F1F2F5]"></div>
            </NuxtLink>
          </template>
        </Lane>
      </div>
      <div v-if="!inited">
        <DfSkeleton class="mb-4 h-10" />
        <LaneSkeleton>
          <div class="overflow-hidden rounded bg-[#FFF] p-2">
            <ImgGroupSkeleton class="w-full" />
            <div class="flex w-full flex-col justify-center gap-2 p-2 mobile:gap-1">
              <div class="df-skeleton h-[22px] w-full rounded"></div>
              <div class="flex h-4 items-center">
                <div class="df-skeleton h-full w-[90px] rounded"></div>
                <div class="df-skeleton h-full w-[90px] rounded"></div>
              </div>
            </div>
          </div>
        </LaneSkeleton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SEE_MORE_PNG from '~/assets/img/block-card/SeeMore.png';

import { getCollectionFeaturedListApi, refreshCollectionListApi } from '~/api';
import Lane from '~/components/common/lane/index.vue';
import LaneSkeleton from '../common/LaneSkeleton.vue';
import ImgGroupSkeleton from '../common/ImgGroupSkeleton.vue';
import { useDeduplication } from '~/composables/hooks/use-deduplication';

const SHOW_LEN = 10;
const allItems = useState<any[]>(() => []);
const items = computed(() => {
  const items = allItems.value.slice(0, SHOW_LEN);
  if (allItems.value.length >= SHOW_LEN) {
    items.push({});
  }
  return items;
});

const mounted = ref(false);
const loaded = useState(() => false);
const inited = computed(() => loaded.value && mounted.value);
const isShow = computed(() => {
  return !(loaded.value && allItems.value.length === 0);
});

const { deduplicate, reset } = useDeduplication('id');
const load = async (random: boolean) => {
  try {
    const res = await getCollectionFeaturedListApi.fetch({
      top: 20,
      sortType: 'hottest',
      collectionMinMakingCount: 4,
      isRandomTop: random,
      pageSource: 'home',
    });
    if (res.code !== RespCode.SUCCESS) {
      return;
    }
    allItems.value = deduplicate(res.data.list);
    // console.log('[home-featured]', allItems.value);
  } finally {
    loaded.value = true;
  }
};
const refresh = async () => {
  reset();
  const res = await refreshCollectionListApi.fetch({
    collectionIds: allItems.value.slice(0, 10).map((item: any) => item.id),
  });
  if (res.code === 0) {
    const list = res.data.list ?? [];
    const all = toRaw(allItems.value);
    list.forEach((item, index) => {
      all[index] = item;
    });
    allItems.value = deduplicate(all);
  }
};

// 首页有缓存需要重新获取数据
onMounted(async () => {
  try {
    if (!loaded.value) {
      return;
    }
    await load(true);
  } finally {
    mounted.value = true;
  }
});

// 路由激活时刷新
onActivated(async () => {
  if (!mounted.value) {
    return;
  }
  await refresh();
});

if (!loaded.value) {
  if (import.meta.client) {
    load(true);
  } else {
    await load(true);
  }
}

if (import.meta.client) {
  console.log('[home-collections]', items.value);
}

const { sensorsSeeMore } = useSensors();
const onSeeMoreClick = () => {
  sensorsSeeMore({
    block_id: '' as any,
    block_name: 'Featured Collections',
    clickSource: 'homepage',
  });
};
</script>

<style scoped lang="less">
:deep(.df-lane-wrap) {
  .df-lane {
    @media (max-width: 1024px) {
      --c: 3;
    }
  }
  .df-lane-item {
    padding-bottom: 16px;
  }
  .df-lane-arrow {
    top: calc(50% - 16px);
    @media (max-width: 767px) {
      display: none;
    }
  }
  //.df-lane-item {
  //  transition: box-shadow 0.2s;
  //  &:hover {
  //    box-shadow: 0 4px 32px 0 #0000000f, 0 4px 16px 0 #0000000d, 0 2px 8px 0 #0000000a;
  //  }
  //}
}
.see-more-card {
  background-color: #fff;
  transition-property: box-shadow;
  &:hover {
    box-shadow:
      0 4px 32px 0 #0000000f,
      0 4px 16px 0 #0000000d,
      0 2px 8px 0 #0000000a;
  }
}
</style>
