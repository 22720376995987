import { Methods, request, REQUEST_SEVER } from '@/utils/request';
import type { ListWrap } from '~/types';
// 获取合集内容详情
export const getCollectionDetailApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/collection/${id}`,
    method: Methods.GET,
  });
};

export type collectionListSorteType = 'latest' | 'hottest' | 'view' | 'like';

// 获取合集的作品列表
export const getCollectionListApi = (id: number, params: { current: number; pageSize: number }, sorter: collectionListSorteType) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/collection/${id}/items`,
    method: Methods.GET,
    params: {
      ...params,
      sorter,
    },
  });
};

export const getCollectionFeaturedListApi = defineFindApi({
  url: '/community/v1/web/collections/featured',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    sortType?: 'hottest' | 'most_user_viewed'; // 排序类型: hottest 热度(不传默认), most_viewed 最多浏览
    top?: number; // 取排序的前top数据，如果不传默认是10
    shieldCollectionIds?: number[]; // 数组，屏蔽指定的合集ID
    collectionMinMakingCount?: number; // 合集最小案例数量，不传则默认为4
    isRandomTop?: boolean; // 是否随机取top数据，如果不传默认是false
    pageSource?: 'home' | '';
  }>(),
  respTy: RespTy.Json<ListWrap>(),
});

export const refreshCollectionListApi = defineFindApi({
  url: '/community/v1/web/collections/card/list',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    collectionIds: number[];
  }>(),
  respTy: RespTy.Json<ListWrap>(),
});

export const getCollectionsAggregationApi = defineFindApi({
  url: '/community/v1/web/collections/aggregation',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    current: number;
    sortType?: string;
    pageSize: number;
  }>(),
});
