<template>
  <section v-if="isShow" class="contests mb-10 px-10 mobile:hidden mobile:px-4">
    <div class="contests-wrap mx-auto w-full" style="max-width: var(--max-w)">
      <svg style="position: absolute; width: 0; height: 0; overflow: hidden">
        <symbol id="icon-contests-cup" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            :d="'M18.3331 6.80197L18.3331 6.86241C18.3331 7.57969 18.3331 7.93834 18.1604 8.23177C17.9878 8.52519 17.6743 8.69937 17.0472 9.04772L16.3861 9.41501C16.8417 7.87489 16.9937 6.22014 17.0499 4.805C17.0523 4.74432 17.055 4.6829 17.0577 4.62082L17.0597 4.57734C17.6024 4.76582 17.9071 4.90633 18.0972 5.17009C18.3332 5.49746 18.3331 5.9323 18.3331 6.80197Z'"
            fill="currentColor"
          />
          <path
            :d="'M1.6665 6.80197L1.66651 6.86241C1.66653 7.57969 1.66654 7.93834 1.83919 8.23177C2.01185 8.52519 2.32536 8.69937 2.95238 9.04772L3.61384 9.41521C3.15823 7.87503 3.00623 6.2202 2.95005 4.805C2.94764 4.74432 2.94492 4.6829 2.94218 4.62083L2.94026 4.57724C2.39734 4.76577 2.09255 4.90628 1.90242 5.17009C1.66647 5.49746 1.66648 5.9323 1.6665 6.80197Z'"
            fill="currentColor"
          />
          <path
            :d="'M13.6474 1.95564C12.7107 1.79759 11.4863 1.66669 9.99996 1.66669C8.51368 1.66669 7.2892 1.79759 6.35249 1.95564C5.40354 2.11575 4.92907 2.1958 4.53264 2.68405C4.13621 3.17229 4.15716 3.7 4.19906 4.75541C4.34291 8.37866 5.1248 12.9042 9.37484 13.3047V16.25H8.18301C7.78577 16.25 7.44376 16.5304 7.36586 16.9199L7.20817 17.7084H4.99984C4.65466 17.7084 4.37484 17.9882 4.37484 18.3334C4.37484 18.6785 4.65466 18.9584 4.99984 18.9584H14.9998C15.345 18.9584 15.6248 18.6785 15.6248 18.3334C15.6248 17.9882 15.345 17.7084 14.9998 17.7084H12.7915L12.6338 16.9199C12.5559 16.5304 12.2139 16.25 11.8167 16.25H10.6248V13.3048C14.8751 12.9044 15.657 8.37874 15.8009 4.75541C15.8428 3.7 15.8637 3.17229 15.4673 2.68405C15.0709 2.1958 14.5964 2.11575 13.6474 1.95564Z'"
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
          />
        </symbol>
        <svg id="icon-contests-enter" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            :d="'M4.41389 17.859C4.77171 17.6504 5.23093 17.7713 5.43959 18.1291C6.26646 19.5471 7.53055 20.6193 9.08943 21.3151C9.46768 21.4839 9.63745 21.9274 9.46864 22.3057C9.29982 22.6839 8.85633 22.8537 8.47808 22.6849C6.66657 21.8764 5.14677 20.6046 4.14382 18.8847C3.93516 18.5269 4.05607 18.0677 4.41389 17.859Z'"
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
          />
          <path
            :d="'M18.9059 3.92194C17.892 2.88646 16.446 2.50452 15.0305 2.9073C14.6321 3.02066 14.2172 2.78959 14.1038 2.39119C13.9905 1.99279 14.2216 1.57792 14.62 1.46456C16.5581 0.913072 18.5746 1.43959 19.9776 2.8725C20.2674 3.16846 20.2624 3.64331 19.9665 3.9331C19.6705 4.2229 19.1957 4.2179 18.9059 3.92194Z'"
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
          />
          <path
            :d="'M11.1902 3.30839C10.9762 2.94131 10.3524 2.7187 9.7187 3.08085C9.08735 3.44168 8.9763 4.07772 9.18664 4.4384L11.7123 8.76952C11.921 9.12734 11.8 9.58656 11.4422 9.79522C11.0844 10.0039 10.6252 9.88296 10.4165 9.52514L7.04897 3.75032C6.83492 3.38324 6.21111 3.16063 5.57743 3.52278C4.94608 3.88361 4.83503 4.51965 5.04537 4.88033L8.83385 11.377C9.04251 11.7348 8.92159 12.1941 8.56377 12.4027C8.20596 12.6114 7.74673 12.4905 7.53807 12.1326L5.8543 9.24522C5.64025 8.87814 5.01644 8.65553 4.38276 9.01768C3.75141 9.37851 3.64036 10.0145 3.8507 10.3752L7.63918 16.8719C9.24812 19.631 13.2185 20.5264 16.5855 18.6021C19.9501 16.6792 21.1462 12.8377 19.5409 10.085L17.0153 5.75387C16.8012 5.38679 16.1774 5.16418 15.5437 5.52633C14.9124 5.88716 14.8013 6.5232 15.0117 6.88389L16.6954 9.7713C16.7962 9.94411 16.8238 10.15 16.772 10.3432C16.7202 10.5365 16.5934 10.701 16.4197 10.8003C14.8772 11.6818 14.4045 13.3863 15.0798 14.5443C15.2885 14.9022 15.1676 15.3614 14.8098 15.57C14.4519 15.7787 13.9927 15.6578 13.7841 15.3C12.8713 13.7348 13.2401 11.8501 14.419 10.5181C14.7486 10.1457 14.8615 9.60396 14.6109 9.17438L11.1902 3.30839Z'"
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
          />
        </svg>
      </svg>
      <div v-show="inited">
        <div class="mb-4 flex items-center justify-between py-1">
          <h2 class="mb-0 text-level-1 font-semibold text-[#070B10]">Contests</h2>
          <NuxtLink to="/creator/center/contests" class="inline-flex items-center gap-1 text-[#070B10]" @click="onTraceContestClick('more')">
            <span class="text-level-4 font-[500]">More</span>
            <ClientOnly>
              <icon-font type="icon-arrow-right-m" class="text-[16px]" />
            </ClientOnly>
          </NuxtLink>
        </div>
        <div class="relative" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
          <Carousel
            ref="carouselRef"
            :items="items"
            :gap="1"
            :show-arrow="false"
            :show-indicator="false"
            :auto="!isPaused"
            :auto-play-speed="10000"
            @change="onChange"
          >
            <template #item="{ item, index }">
              <Banner :contest="item" :seq="index + 1" :inited="inited" />
            </template>
          </Carousel>
          <div v-if="items.length > 1" role="menu" class="dots mx-auto mt-2.5 flex w-fit">
            <span
              v-for="(_, index) in items"
              :key="index"
              role="menuitem"
              :class="index === activeIndex ? 'active' : ''"
              @click="onDotClick(index)"
            ></span>
          </div>
          <ClientOnly>
            <icon-font class="arrow prev" type="icon-arrow-left-m" @click="onPrev" />
            <icon-font class="arrow next" type="icon-arrow-right-m" @click="onNext" />
          </ClientOnly>
        </div>
      </div>
      <div v-if="!inited">
        <DfSkeleton class="mb-4 h-10" />
        <DfSkeleton ratio="21.42%" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { isNil } from 'lodash-es';
import { getRecommendActivitiesApi } from '~/api';
import Carousel from '~/components/common/carousel/index.vue';
import type { CarouselInstance } from '~/components/common/carousel/types';
import Banner from './Banner.vue';

const { traceContestsClick } = useSensors();
const onTraceContestClick = (click_element: string) => {
  traceContestsClick({
    click_element,
  });
};

const items = useState(() => [] as any[]);

const mounted = ref(true);
const loaded = useState(() => false);
const inited = computed(() => loaded.value && mounted.value);
const isShow = computed(() => {
  return !(loaded.value && items.value.length === 0);
});

const load = async () => {
  try {
    const res = await getRecommendActivitiesApi.fetch();
    items.value = res.data.list ?? [];
  } finally {
    loaded.value = true;
  }
};

onMounted(async () => {
  try {
    if (!loaded.value || mounted.value) {
      return;
    }
    await load();
  } finally {
    mounted.value = true;
  }
});

if (!loaded.value) {
  if (import.meta.client) {
    load();
  } else {
    await load();
  }
}
if (import.meta.client) {
  console.log('[home-contests]', items.value);
}

const activeIndex = ref(0);
const onChange = (index: number) => {
  activeIndex.value = index;
};

const carouselRef = shallowRef<CarouselInstance>();
const onPrev = () => {
  carouselRef.value?.prev();
};
const onNext = () => {
  carouselRef.value?.next();
};
const onDotClick = (index: number) => {
  if (!isNil(carouselRef.value)) {
    carouselRef.value.move(index);
  }
};
const isPaused = ref(false);
const onMouseEnter = () => {
  isPaused.value = true;
};
const onMouseLeave = () => {
  isPaused.value = false;
};
</script>

<style scoped lang="less">
.dots {
  span {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    padding: 0;
    margin-left: 6px;
    border: none;
    background: #070b10;
    opacity: 0.1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.active {
      width: 28px;
      background: #070b10;
      opacity: 1;
    }
  }
}
.arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  color: theme('colors.text.primary');
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 4px 16px 0 #0000001a;

  &.prev {
    left: 0;
    transform: translate(-50%, -50%);
  }
  &.next {
    right: 0;
    transform: translate(50%, -50%);
  }
}
</style>
