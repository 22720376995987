<template>
  <div class="author-user-img-wrap flex items-center">
    <!-- 服务端不要 popover 避免警告 -->
    <AuthorInner v-if="!isMounted" :user-id="userId" :user-img="userImg" :user-name="userName" :img-size="imgSize" :clickable="clickable" />
    <AuthorPopover v-else :user-id="userId" :block-id="blockId" :block-name="blockName" :disabled="isShowUserCard" :link-target="linkTarget">
      <AuthorInner :user-id="userId" :user-img="userImg" :user-name="userName" :img-size="imgSize" :clickable="clickable" />
    </AuthorPopover>
  </div>
</template>

<script lang="ts" setup>
type PropsType = {
  userId: number;
  userImg?: string;
  userName?: string;
  imgSize?: string;
  blockId?: number | null;
  blockName?: string | null;
  clickable?: boolean;
  isShowUserCard?: boolean;
  linkTarget?: '_blank' | '_self';
};
withDefaults(defineProps<PropsType>(), {
  userName: '',
  userImg: '',
  imgSize: '24px',
  isShowUserCard: false,
  blockId: null,
  blockName: null,
  clickable: true,
  linkTarget: '_self',
});

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>
