<template>
  <section v-if="isShow" class="ranking mb-10 px-10 mobile:hidden mobile:px-4">
    <div class="ranking-wrap mx-auto w-full" style="max-width: var(--max-w)">
      <svg style="position: absolute; width: 0; height: 0; overflow: hidden">
        <symbol id="icon-ranking-left" width="11" height="22" viewBox="0 0 11 22">
          <path
            :d="'M7.59269 2.15708C7.68811 2.28492 8.5946 2.30653 9.35787 1.73725C10.0502 1.22056 10.4009 0.402432 10.3099 0.130211C10.0751 -0.0342479 9.19068 0.0682889 8.49833 0.58498C7.73506 1.15426 7.49726 2.02925 7.59269 2.15708Z'"
            fill="currentColor"
          />
          <path
            :d="'M5.1255 4.49796C5.29745 4.55571 6.15617 3.98863 6.49935 2.96417C6.8108 2.03456 6.60829 1.04503 6.34755 0.849901C6.02216 0.848775 5.26387 1.51659 4.95242 2.4462C4.60966 3.47096 4.95397 4.44051 5.1255 4.49796Z'"
            fill="currentColor"
          />
          <path
            :d="'M3.69944 7.66017C3.89405 7.62563 4.41965 6.63358 4.2121 5.47304C4.02392 4.42006 3.31159 3.57872 2.96064 3.52856C2.64862 3.69763 2.2722 4.73344 2.46038 5.78642C2.66745 6.94667 3.50453 7.6952 3.69944 7.66017Z'"
            fill="currentColor"
          />
          <path
            :d="'M3.44912 10.9381C3.61337 10.8238 3.67642 9.68958 2.99502 8.71141C2.37652 7.8236 1.36632 7.35182 1.02191 7.45394C0.806529 7.74146 0.899221 8.85274 1.51772 9.74054C2.19913 10.7187 3.28487 11.0524 3.44912 10.9381Z'"
            fill="currentColor"
          />
          <path
            :d="'M4.67582 14.2685C4.79222 14.0798 4.35579 12.8979 3.23035 12.2037C2.20865 11.5736 0.97242 11.5397 0.667826 11.7959C0.575539 12.1836 1.16091 13.2729 2.18173 13.9031C3.3077 14.5977 4.55908 14.4577 4.67582 14.2685Z'"
            fill="currentColor"
          />
          <path
            :d="'M6.76978 16.9045C6.81561 16.6875 6.00446 15.7235 4.70993 15.4515C3.53539 15.2047 2.36104 15.5922 2.16106 15.9363C2.20578 16.3319 3.12495 17.159 4.29987 17.4053C5.59401 17.6777 6.72445 17.1218 6.76978 16.9045Z'"
            fill="currentColor"
          />
          <path
            :d="'M10.204 19.5482C10.2292 19.2994 9.20337 18.3155 7.71824 18.1656C6.37135 18.0299 5.09952 18.6052 4.9168 19.0155C5.01412 19.4545 6.14529 20.2716 7.49219 20.4074C8.97731 20.5573 10.1788 19.797 10.204 19.5482Z'"
            fill="currentColor"
          />
        </symbol>
        <symbol id="icon-ranking-right" width="11" height="22" viewBox="0 0 11 22">
          <path
            :d="'M3.50711 2.15708C3.41168 2.28492 2.50519 2.30653 1.74192 1.73725C1.04957 1.22056 0.698895 0.402432 0.789905 0.130211C1.02473 -0.0342479 1.90911 0.0682889 2.60146 0.58498C3.36474 1.15426 3.60253 2.02925 3.50711 2.15708Z'"
            fill="currentColor"
          />
          <path
            :d="'M5.97497 4.49814C5.80301 4.55589 4.9443 3.98881 4.60111 2.96435C4.28967 2.03474 4.49218 1.04521 4.75292 0.850084C5.0783 0.848958 5.8366 1.51677 6.14805 2.44638C6.4908 3.47114 6.1465 4.4407 5.97497 4.49814Z'"
            fill="currentColor"
          />
          <path
            :d="'M7.40035 7.66005C7.20574 7.62551 6.68014 6.63346 6.88769 5.47291C7.07587 4.41993 7.7882 3.57859 8.13915 3.52843C8.45118 3.6975 8.82759 4.73332 8.63941 5.7863C8.43234 6.94655 7.59526 7.69507 7.40035 7.66005Z'"
            fill="currentColor"
          />
          <path
            :d="'M7.65067 10.9381C7.48642 10.8238 7.42337 9.68958 8.10477 8.71141C8.72327 7.8236 9.73347 7.35182 10.0779 7.45394C10.2933 7.74146 10.2006 8.85274 9.58207 9.74054C8.90067 10.7187 7.81492 11.0524 7.65067 10.9381Z'"
            fill="currentColor"
          />
          <path
            :d="'M6.42397 14.2685C6.30757 14.0798 6.744 12.8979 7.86944 12.2037C8.89114 11.5736 10.1274 11.5397 10.432 11.7959C10.5243 12.1836 9.93888 13.2729 8.91806 13.9031C7.79209 14.5977 6.54072 14.4577 6.42397 14.2685Z'"
            fill="currentColor"
          />
          <path
            :d="'M4.32974 16.9045C4.28391 16.6875 5.09506 15.7235 6.38959 15.4515C7.56413 15.2047 8.73848 15.5922 8.93846 15.9363C8.89374 16.3319 7.97457 17.159 6.79965 17.4053C5.50551 17.6777 4.37506 17.1218 4.32974 16.9045Z'"
            fill="currentColor"
          />
          <path
            :d="'M0.896161 19.5482C0.870976 19.2994 1.89682 18.3155 3.38195 18.1656C4.72884 18.0299 6.00067 18.6052 6.18339 19.0155C6.08607 19.4545 4.9549 20.2716 3.608 20.4074C2.12288 20.5573 0.921346 19.797 0.896161 19.5482Z'"
            fill="currentColor"
          />
        </symbol>
      </svg>
      <div v-show="inited">
        <h2 class="mb-0 py-1 text-level-1 font-semibold text-[#070B10]">Trending</h2>
        <div v-if="showTabs" class="h-[46px]">
          <ClientOnly>
            <a-tabs v-model:activeKey="activeKey" @change="onTabChange">
              <a-tab-pane v-for="ranking in rankingList" :key="ranking.title" :tab="ranking.title" />
            </a-tabs>
          </ClientOnly>
        </div>
        <div
          v-for="ranking in rankingList"
          v-show="activeKey === ranking.title"
          :key="ranking.title"
          :data-sensors-exposure-event-name="GA4Event.RANKING_EXPOSURE"
          :data-sensors-exposure-property-ranking_name="ranking.title"
          class="items mt-4 grid gap-x-4 gap-y-3"
        >
          <Item v-for="(item, index) in rankingToMakingMap[ranking.title]" :key="item.id" :ranking-num="index + 1" :making="item" class="item" />
        </div>
      </div>
      <div v-if="!inited">
        <DfSkeleton class="h-[86px]" />
        <div class="items mt-4 grid gap-x-4 gap-y-3">
          <DfSkeleton v-for="item in 9" :key="item" class="item h-[127px]" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Item from './Item.vue';
import { getBlockExtDataListApi, getRankingsListApi } from '~/api';
import { GA4Event } from '~/types/ga4';

const { traceRankingClick } = useSensors();

const activeKey = useState(() => '');

const rankingList = useState(() => [] as any[]);
const rankingToMakingMap = computed(() => {
  return rankingList.value.reduce(
    (acc, item) => {
      const makings = (item?.makingCardViewList ?? []) as any[];
      acc[item.title] = range(Math.min(makings.length, 9), (index) => makings[index]);
      return acc;
    },
    {} as Record<string, any[]>,
  );
});
const makings = computed(() => rankingToMakingMap.value[activeKey.value]);

const showTabs = computed(() => {
  return rankingList.value.length > 1 && rankingList.value.some((item) => (item?.makingCardViewList ?? []).length > 0);
});

const onTabChange = () => {
  traceRankingClick({
    ranking_name: activeKey.value,
    click_element: 'tab',
  });
  update();
};

const mounted = ref(true);
const loaded = useState(() => false);
const inited = computed(() => loaded.value && mounted.value);
const isShow = computed(() => {
  return !(loaded.value && rankingList.value.length === 0);
});

const load = async () => {
  try {
    const res = await getRankingsListApi.fetch();
    const list = res.data?.list || [];
    rankingList.value = list.filter((item: any) => (item.makingCardViewList ?? []).length > 0);
    activeKey.value = activeKey.value || rankingList.value[0]?.title;
  } finally {
    loaded.value = true;
  }
};
// 更新案例最新数据
const update = async () => {
  const res = await getBlockExtDataListApi.fetch({
    ids: makings.value.map((item: any) => item.id),
  });
  const idToMakingMap = (res.data ?? {}) as Record<string, any>;
  makings.value.forEach((item: any) => {
    const newItem = idToMakingMap[item.id];
    if (newItem && !newItem.isDeleted) {
      Object.assign(item, newItem);
    }
  });
};

onMounted(async () => {
  try {
    if (!loaded.value || mounted.value) {
      return;
    }
    await load();
  } finally {
    mounted.value = true;
  }
});

onActivated(async () => {
  if (!mounted.value || !loaded.value) {
    return;
  }
  await update();
});

if (!loaded.value) {
  if (import.meta.client) {
    load();
  } else {
    await load();
  }
}
if (import.meta.client) {
  console.log('[home-rankings]', rankingList.value);
}
</script>

<style scoped lang="less">
.ranking {
  .items {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .item:nth-of-type(n + 9) {
        display: none;
      }
    }
  }

  :deep(.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list) {
    padding-bottom: 6px;
  }
  :deep(.ant-tabs .ant-tabs-tab + .ant-tabs-tab) {
    margin: 0 0 0 24px;
  }
  :deep(.ant-tabs .ant-tabs-tab) {
    padding: 9px 0;

    & .ant-tabs-tab-btn {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #86909c;
      &:hover {
        color: #070b10;
      }
    }
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #070b10;
    }
  }
  :deep(.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar) {
    height: 4px;
    background: transparent;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 24px;
      height: 100%;
      border-radius: 3px;
      background: #ff0035;
    }
  }
  :deep(.ant-tabs-top > .ant-tabs-nav::before) {
    border-bottom: 1px solid #e5e7eb;
  }
}
</style>
