import type { OBJECT_TYPE } from '@/stores/types';
import type { ObjectType, TrackType } from '@/types/track';
import { Methods, request, REQUEST_SEVER } from '@/utils/request';
import { defineFindApi } from '~/utils/fetch/define-api';

// 查找用户
export const searchUserListApi = (params: { current: number; pageSize: number; q: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/users',
    method: Methods.GET,
    params,
  });
};

// 获取我的收藏
export const getMyFavoriteListApi = (params: {
  // current: number;
  // pageSize: number;
  type: string;
}) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/favorites',
    method: Methods.GET,
    params,
  });
};

// 获取我的创作列表
export const getMyMakingListApi = defineFindApi({
  url: '/community/v1/web/my/makings',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    current: number;
    pageSize: number;
    forSale?: 0 | 1; // 0:全部 1:付费
    hintLabelType?: string;
  }>(),
});

// 获取我的问题列表
export const getMyQuestionListApi = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/questions',
    method: Methods.GET,
    params,
  });
};

// 获取我的回答列表
export const getMyAnswerListApi = (params: { current: number; pageSize: number; sorter?: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/answers',
    method: Methods.GET,
    params,
  });
};

// 获取我关注的问答
export const getMyFollowListApi = (params: { current: number; pageSize: number; objectType: OBJECT_TYPE }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/followings',
    method: Methods.GET,
    params,
  });
};

// 获取我发表的讨论
export const getMyDiscussionListApi = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/discussions',
    method: Methods.GET,
    params,
  });
};

// 获取我的回复列表
export const getMyReplyListApi = (params: { current: number; pageSize: number; sorter?: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/replies',
    method: Methods.GET,
    params,
  });
};

// 获取我的信息
export const getMyInfoApi = defineFindApi({
  url: '/community/v1/web/my/info',
  method: Methods.GET,
});

// 获取用户信息
export const getUserInfoApi = (id: number, config?: Record<string, any>) => {
  return request[REQUEST_SEVER.DEFAULT](
    {
      url: `/community/v1/web/user/${id}/info`,
      method: 'GET',
    },
    {
      ...config,
    },
  );
};

// 根据ID获取用户信息
export const getUserInfoById = defineFindApi({
  url: '/community/v1/web/user/:id/info',
  method: Methods.GET,
  paramTy: ParamTy.Query<{
    id: number;
  }>(),
});

// 获取用户创作列表
export const getUserMakingListApi = (id: number, params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/user/${id}/makings`,
    method: 'GET',
    params,
  });
};
// 获取我的关注列表
export const getMyFollowingList = (
  params: {
    current: number;
    pageSize: number;
    objectType: string;
  },
  config?: any,
) => {
  return request[REQUEST_SEVER.DEFAULT](
    {
      url: '/community/v1/web/my/followings',
      method: Methods.GET,
      params,
    },
    {
      ...config,
    },
  );
};

// 获取我的粉丝列表
export const getMyFollowersList = (
  params: {
    current: number;
    pageSize: number;
  },
  config?: any,
) => {
  return request[REQUEST_SEVER.DEFAULT](
    {
      url: '/community/v1/web/my/followers',
      method: Methods.GET,
      params,
    },
    {
      ...config,
    },
  );
};
// 移除粉丝
export const removeFollower = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/follower/${id}`,
    method: Methods.DELETE,
  });
};
// 用户操作
export const doActionApi = (
  action: string,
  data: {
    objectType: OBJECT_TYPE;
    objectId: number;
    contentType?: string;
    revoke?: boolean;
  },
) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/action/${action}`,
    method: 'POST',
    data,
  });
};
// 获取我的设计文件列表
export const getDesignFileList = (params: { type: string; ids?: number[]; current?: number; pageSize?: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/files`,
    method: Methods.GET,
    params,
  });
};
// 获取云文件列表
export const getCloudDesignFileList = defineFindApi({
  url: '/xcs-cloud/v1/front/project/dfandcloud',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any>(),
  paramTy: ParamTy.Query<{
    current: number;
    pageSize: number;
  }>(),
});
// 获取云文件详情
export const getDesignFileDetail = (params: { id: string; type: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/xcs-cloud/v1/front/project/dfandcloud/detail',
    method: Methods.GET,
    params,
  });
};
// 获取文件详细参数
export const getDesignFileInfo = (params: { ids: number[] }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/file/:id`,
    method: Methods.GET,
    params,
  });
};
// 上传文件
export const postDesignFile = (data: { name: string; url: string; type: string; fileSize: number; extra: any }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/file`,
    method: Methods.POST,
    data,
  });
};
// 删除文件
export const deleteDesignFile = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/file/${id}`,
    method: Methods.DELETE,
  });
};
// 帖子最近使用
export const getLatestUse = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/lately-use`,
    method: Methods.GET,
  });
};
// 获取白名单状态
export const getWhitesStatus = defineFindApi({
  url: '/community/v1/web/my/whites',
  method: HttpMethod.GET,
});
// 获取草稿箱数量

export const getDraftCount = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/draft/count',

    method: Methods.GET,
  });
};

// 获取草稿箱数量
export const fetchDraftCount = defineFindApi({
  url: '/community/v1/web/my/draft/count',
  method: Methods.GET,
});

// 获取用户近期关注top10
export const getTopFollowingList = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/user/top/followings',
    method: Methods.GET,
  });
};
// 我的收藏-案例
export const getMyFavoriteMakingList = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/favorite/makings',
    method: Methods.GET,
    params,
  });
};
// 我的收藏-合集
export const getMyFavoriteCollectionList = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/favorite/collections',
    method: Methods.GET,
    params,
  });
};
// 我的点赞-案例
export const getMyLikeMakingList = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/mark/makings',
    params: {
      ...params,
      action: 'like',
    },
  });
};
// 我的点赞-合集
export const getMyLikeCollectionList = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/mark/collections',
    params: {
      ...params,
      action: 'like',
    },
  });
};

// 获取获取最新热门作品(30天内热门)
export const getTopHotAssetsList = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/making/latest/hots',
    method: Methods.GET,
  });
};

// 获取合集list
export const getAllCollectionListApi = (params: {
  current: number;
  pageSize: number;
  sorter?: string;
  collectionMakingCount?: number;
  loadMakingDetails?: boolean;
}) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/collections',
    method: Methods.GET,
    params,
  });
};

// 获取近期销量最高的案例
export const getTopSale = (params?: { top?: number; isExcludeBuyer?: boolean }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/making/sale/tops',
    method: Methods.GET,
    params,
  });
};
// 获取近期销量最高的案例
export const getMyCredits = (params: { current: number; pageSize: number; outInType?: 'in' | 'out' | '' }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/credits/history',
    method: Methods.GET,
    params,
  });
};

export const getCollectionFeature = (params: {
  current: number;
  pageSize: number;
  sorter?: string;
  collectionMakingCount?: number;
  loadMakingDetails?: boolean;
  shieldCollectionIds?: number[]; // 屏蔽的合集id
  isRandomTop?: boolean; // 是否随机取排序的前top数据
  collectionMinMakingCount?: number; // 合集最小作品数
}) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/collections/featured',
    method: Methods.GET,
    params,
  });
};
export const getMyObjectTrack = (params: { objectType: ObjectType; objectId: number; trackType: TrackType }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/object-track/statistics',
    method: Methods.GET,
    params,
  });
};
export const postMyObjectTrack = (data: { objectType: ObjectType; objectId: number; trackType: TrackType }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/object-track',
    method: Methods.POST,
    data,
  });
};
