/**
 * GA4埋点, 使用示例
 * const { addCommonProperty, sendEvent, configureEvent } = useGA4Tracking();
 * // 添加公共属性
 * addCommonProperty('user_id', '12345');
 * // 发送埋点事件
 * sendEvent('custom_click', { label: '示例按钮' });
 * // 自定义配置 event 事件
 * const customClickEvent = configureEvent('custom_click', { category: 'button' });
 * // 发送自定义事件
 * const handleClick = () => {
 *  customClickEvent({ label: '示例按钮' });
 * };
 */

// 定义公共属性
const commonProperties: Record<string, any> = {};

// 添加公共属性
export const addCommonProperty = (key: string, value: string) => {
  commonProperties[key] = value;
};

// 删除公共属性
export const removeCommonProperty = (key: string) => {
  delete commonProperties[key];
};

// 发送埋点事件
export const sendEvent = (eventName: string, eventParams: Record<string, any> = {}) => {
  try {
    if (window.dataLayer) {
      const combinedParams = {
        ...commonProperties,
        ...eventParams,
        event: eventName,
      };
      window.dataLayer.push(combinedParams);
      // console.log('window.dataLayer :>> ', window.dataLayer);
    }
  } catch (err) {
    console.error('GA4-error', err);
  }
};

// 自定义配置 event 事件的地方
export const configureEvent = (eventName: string, customParams: Record<string, any> = {}) => {
  return (eventParams: Record<string, any> = {}) => {
    const finalParams = {
      ...customParams,
      ...eventParams,
    };
    sendEvent(eventName, finalParams);
  };
};

export function useGA4Tracking() {
  return {
    addCommonProperty,
    removeCommonProperty,
    sendEvent,
    configureEvent,
  };
}
