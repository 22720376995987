export function useDeduplication(key = 'id') {
  const ids = new Set<any>();
  const deduplicate = (arr: any[]) => {
    return arr.filter((item) => {
      if (!ids.has(item[key])) {
        ids.add(item[key]);
        return true;
      } else {
        return false;
      }
    });
  };
  const reset = () => ids.clear();
  return { ids, deduplicate, reset };
}
