<template>
  <ClientOnly v-if="isAnimating && animation">
    <Vue3Lottie class="like-lottie" :animation-data="LikeAmination" :loop="false" :auto-play="true" :height="size" :width="size" />
  </ClientOnly>
  <template v-else>
    <icon-font
      v-if="isLiked && iconType === 'icon-font'"
      type="icon-filled-thumb-up"
      :class="`text-[${size}px] text-[${likeColor}]`"
      @click="handleLikeClick(false)"
    />
    <NuxtIcon
      v-if="isLiked && iconType === 'nuxt-icon'"
      name="df:icon-filled-thumb-up"
      :class="`text-[${size}px] text-[${likeColor}]`"
      @click="handleLikeClick(false)"
    />
    <icon-font
      v-if="!isLiked && iconType === 'icon-font'"
      type="icon-thumb-up"
      :class="`text-[${size}px] ${unlikeColor ? 'text-[' + unlikeColor + ']' : ''} hover:text-[${hoverColor}]`"
      @click="handleLikeClick(true)"
    />
    <NuxtIcon
      v-if="!isLiked && iconType === 'nuxt-icon'"
      name="df:icon-thumb-up"
      :class="`text-[${size}px] ${unlikeColor ? 'text-[' + unlikeColor + ']' : ''} hover:text-[${hoverColor}]`"
      @click="handleLikeClick(true)"
    />
  </template>
</template>

<script setup lang="ts">
import LikeAmination from '@/assets/animate/like.json';
const isAnimating = ref(false);
interface Props {
  isLiked: boolean;
  size?: number;
  animation?: boolean;
  likeColor?: string;
  hoverColor?: string;
  unlikeColor?: string;
  iconType?: 'icon-font' | 'nuxt-icon';
}
interface Emits {
  (e: 'update:liked', value: boolean): void;
}
// 如果使用动画，不传color属性，避免和动画颜色不一致
const props = withDefaults(defineProps<Props>(), {
  size: 16, // 动效默认宽高
  animation: true, // 是否开启动画,有异步数据获取的情况，可用该参数动态控制
  likeColor: '#ff0035', // 点赞颜色
  hoverColor: '', // 鼠标hover颜色
  unlikeColor: '', // 未点赞颜色
  iconType: 'icon-font', // 图标类型
});
const emit = defineEmits<Emits>();

let stopWatch = null as null | (() => void);
watchEffect(() => {
  if (props.animation && !stopWatch) {
    stopWatch = watch(
      () => props.isLiked,
      (newVal) => {
        if (newVal) {
          isAnimating.value = true;
        } else {
          isAnimating.value = false;
        }
      },
    );
  } else if (!props.animation && stopWatch) {
    stopWatch();
    stopWatch = null;
  }
});
const handleLikeClick = (type: boolean) => {
  emit('update:liked', type);
};
</script>
<style lang="less" scoped>
div.like-lottie {
  margin: 0;
  line-height: 0px;
}
</style>
