<template>
  <div class="lane-skeleton grid gap-4">
    <template v-if="$slots.default">
      <div v-for="item in 4" :key="item" class="item w-full rounded">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <div v-for="item in 4" :key="item" class="df-skeleton item w-full rounded pb-4">
        <div class="w-full pt-[54.66%]"></div>
        <div class="mt-2 h-11"></div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="less">
.lane-skeleton {
  grid-template-columns: repeat(var(--c, 4), minmax(0, 1fr));
  @media (max-width: 1024px) {
    --c: 3;
    .item:nth-of-type(n + 4) {
      display: none;
    }
  }
}
</style>
